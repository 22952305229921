import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported } from 'firebase/analytics'
import 'hammerjs'
import env from '@ti-platform/fleet-web/env'
import { initAmplify } from '@ti-platform/web/auth'
import { FleetWebAppModule } from './app/app.module'

if (window && window?.location?.hostname !== 'localhost' && env.sentry.enabled) {
  Sentry.init({
    dsn: env.sentry.dsn,
    enabled: true,
    ignoreErrors: ['UserUnAuthenticatedException'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [`${env.apiUrl}/*`],
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: env.sentry.tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (env.firebase) {
  // Initialize Firebase
  const app = initializeApp(env.firebase)
  isSupported().then(() => getAnalytics(app))
}

initAmplify(
  {
    userPoolId: env.amplify.userPoolId,
    identityPoolId: env.amplify.identityPoolId,
    userPoolClientId: env.amplify.userPoolClientId,
  },
  {
    resolveSessionUrl: `${env.apiUrl}/fleet/user/login-session`,
    sessionIdParamName: 'sessionId',
  },
).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(FleetWebAppModule)
    .then(() => {
      const loadingElement = document.getElementById('loading-animation')
      if (loadingElement) {
        loadingElement.parentNode?.removeChild(loadingElement)
      }
    })
    .catch((e) => console.error(`Cannot bootstrap application`, e))
})
