import { Component, HostBinding, inject, Input, OnInit, signal, Type } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, map } from 'rxjs'
import { Action, AppFeatures, Resource } from '@ti-platform/contracts'
import { AuthSession, Profile } from '@ti-platform/web/auth'
import { AccessControl, injectDestroy$, Memoize, selectState } from '@ti-platform/web/common'
import { LanguageService, DeviceService } from '@ti-platform/web/ui-kit/i18n'
import {
  AlertsIconComponent,
  BarChart2IconComponent,
  FavoritesIconComponent,
  GeofenceIconComponent,
  PlusIconComponent,
  ReportsIconComponent,
  RouteIconComponent,
  TruckIconComponent,
  VideoIconComponent,
  WrenchIconComponent,
} from '@ti-platform/web/ui-kit/icons'
import { SidebarBadgesModel, SidebarService } from '@ti-platform/web/ui-kit/layout'
import { BrandingData } from '@ti-platform/web/ui-kit/services/branding.service'
import { BaseUserSettingsModel } from '@ti-platform/web/user'
import { FleetUserSettingsModel } from '../models/user-settings.model'

interface iMenuElement {
  name: string
  path: string[]
  icon?: Type<unknown>
  children?: iMenuElement[]
  isExpanded?: boolean
  cssClass?: string
  counterKey?: string
  actionButton?: Pick<iMenuElement, 'name' | 'path' | 'icon' | 'checkAccess'>
  checkAccess?: [Resource, Action]
  requirePricingPlan?: AppFeatures[]
  hidden?: boolean
}

interface InstallPromptResult {
  outcome: 'accepted' | 'dismissed'
  platform: string
}

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<InstallPromptResult>
  userChoice: Promise<InstallPromptResult>
}

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  providers: [{ provide: BaseUserSettingsModel, useClass: FleetUserSettingsModel }],
})
export class SidebarComponent implements OnInit {
  @Input()
  @HostBinding('class.collapsed')
  public collapsed = false

  @Input()
  public brandingData?: BrandingData | null

  protected readonly accessControl = inject(AccessControl)
  protected readonly authSession = inject(AuthSession)
  protected readonly badgesModel = inject(SidebarBadgesModel)
  protected readonly badges = selectState(SidebarBadgesModel)
  protected readonly sidebarService = inject(SidebarService)
  protected readonly languageService = inject(LanguageService)
  protected readonly profile = inject(Profile)
  protected readonly router = inject(Router)
  protected readonly deviceService = inject(DeviceService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly searchCriteria$ = new BehaviorSubject('')
  protected readonly installPwaEvent$ = new BehaviorSubject<BeforeInstallPromptEvent | undefined>(
    undefined,
  )

  protected readonly menuElements: iMenuElement[] = [
    {
      name: 'fleet.menu.bookmarks',
      icon: FavoritesIconComponent,
      path: ['favourites'],
      requirePricingPlan: [AppFeatures.Bookmarks],
    },
    {
      name: 'fleet.menu.dashboard',
      icon: BarChart2IconComponent,
      path: ['dashboard'],
      requirePricingPlan: [AppFeatures.Dashboard],
      hidden: true,
    },
    {
      name: 'fleet.menu.fleet',
      icon: TruckIconComponent,
      path: ['fleet'],
      checkAccess: [Resource.Fleet, Action.View],
    },
    {
      name: 'fleet.menu.alters',
      icon: AlertsIconComponent,
      path: ['alerts'],
      checkAccess: [Resource.Fleet, Action.View],
      actionButton: {
        name: 'fleet.menu.alerts_setup',
        path: ['alerts', 'setup'],
        icon: PlusIconComponent,
        checkAccess: [Resource.Alert, Action.Create],
      },
      children: [
        {
          icon: PlusIconComponent,
          name: 'fleet.menu.alerts_setup',
          path: ['alerts', 'setup'],
          cssClass: 'has-bottom-separator',
          checkAccess: [Resource.Alert, Action.Create],
        },
        {
          name: 'fleet.menu.alerts_triggered',
          path: ['alerts', 'triggered'],
          counterKey: 'unreviewedAlerts',
          checkAccess: [Resource.Alert, Action.View],
        },
        {
          name: 'fleet.menu.alerts_created',
          path: ['alerts', 'manage'],
          checkAccess: [Resource.Alert, Action.View],
        },
      ],
    },
    {
      name: 'fleet.menu.video',
      icon: VideoIconComponent,
      path: ['video'],
      requirePricingPlan: [AppFeatures.Video],
      checkAccess: [Resource.Media, Action.View],
      children: [
        {
          name: 'fleet.menu.video_request',
          path: ['video', 'request'],
        },
        {
          name: 'fleet.menu.video_library',
          path: ['video', 'library'],
        },
        {
          name: 'fleet.menu.video_stream',
          path: ['video', 'stream'],
        },
      ],
    },
    {
      name: 'fleet.menu.dispatch',
      icon: RouteIconComponent,
      path: ['dispatch'],
      requirePricingPlan: [AppFeatures.Dispatch],
      hidden: true,
    },
    {
      name: 'fleet.menu.maintenance',
      icon: WrenchIconComponent,
      path: ['maintenance'],
      requirePricingPlan: [AppFeatures.Maintenance],
      hidden: true,
      children: [
        {
          name: 'fleet.menu.maintenance_overdue',
          path: ['maintenance', 'overdue'],
        },
        {
          name: 'fleet.menu.maintenance_status',
          path: ['maintenance', 'status'],
        },
        {
          name: 'fleet.menu.maintenance_reports',
          path: ['maintenance', 'reports'],
        },
        {
          name: 'fleet.menu.maintenance_services',
          path: ['maintenance', 'services'],
        },
        {
          name: 'fleet.menu.maintenance_schedules',
          path: ['maintenance', 'schedules'],
        },
      ],
    },
    {
      name: 'fleet.menu.reports',
      icon: ReportsIconComponent,
      path: ['reports'],
      checkAccess: [Resource.Reports, Action.View],
    },
    {
      name: 'fleet.menu.locations',
      icon: GeofenceIconComponent,
      path: ['locations'],
      checkAccess: [Resource.Location, Action.View],
    },
  ]

  @Memoize()
  protected get menuElements$() {
    return this.searchCriteria$.pipe(
      map((criteria) =>
        criteria?.length
          ? this.menuElements.filter((element) => this.matchElement(element, criteria))
          : this.menuElements,
      ),
    )
  }

  public async ngOnInit() {
    this.badgesModel.init()
    this.initPWAInstall()
  }

  protected initPWAInstall() {
    const listener = (event: any) => this.installPwaEvent$.next(event)
    window.addEventListener('beforeinstallprompt', listener)
    this.destroy$.subscribe(() => window.removeEventListener('beforeinstallprompt', listener))
  }

  protected promptPWAInstall() {
    if (this.installPwaEvent$.value) {
      this.installPwaEvent$.value.prompt().then((result) => {
        console.log(`PWA Install Result`, result)
        if (result.outcome === 'accepted') {
          this.installPwaEvent$.next(undefined)
        }
      })
    }
  }

  protected toggleMenuSearchExpanded() {
    this.sidebarService.collapse()
    const input = document.querySelector<HTMLInputElement>('.search-input')
    if (input) {
      setTimeout(() => input.focus(), 0)
    }
  }

  protected toggleElementExpanded(element: iMenuElement) {
    element.isExpanded = !element.isExpanded
    this.searchCriteria$.next(this.searchCriteria$.value)
  }

  protected matchElement(element: iMenuElement, criteria: string): boolean {
    const regExp = new RegExp(criteria, 'gi')
    const labels = [
      this.languageService.instant(element.name),
      ...(element.children?.map((child) => this.languageService.instant(child.name)) ?? []),
    ]
    return Boolean(labels.find((label) => label.match(regExp)))
  }
}
