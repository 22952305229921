<section class="heading flex">
  @if (brandingData) {
    <div class="logo-small">
      <img [src]="brandingData.logo.sm.href" alt="" />
    </div>
    <div class="logo-large logo-55">
      <img [src]="brandingData.logo.lg.href" alt="" />
    </div>
    <div class="logo-mobile">
      <img [src]="brandingData.logo.lg.href" alt="" />
    </div>
  }
</section>

<p-button
  (click)="toggleMenuSearchExpanded()"
  [severity]="'secondary'"
  [size]="'small'"
  class="h--40 btn-search"
  icon="pi"
>
  <app-icon-search [color]="'var(--icon-color)'" [height]="18" [width]="18"></app-icon-search>
</p-button>

<section class="search p-fluid">
  <p-iconField class="sidebar" iconPosition="left">
    <p-inputIcon>
      <app-icon-search [height]="18" [width]="18"></app-icon-search>
    </p-inputIcon>
    <input
      #searchInput
      (keyup)="searchCriteria$.next(searchInput.value)"
      [placeholder]="'button.search' | translate"
      [value]="searchCriteria$ | async"
      class="h--40 search-input"
      pInputText
      type="text"
    />
  </p-iconField>
</section>

<section
  class="navigation scrollbar-gray invisible-collapsed"
  [ngClass]="{ scrollable: !collapsed }"
>
  @for (element of menuElements$ | async; track element; let i = $index) {
    <div
      class="navigation-item {{ element.cssClass }}"
      [class.hidden]="element.hidden"
      [class.expanded]="element.isExpanded"
      [class.has-children]="element.children?.length"
      *appCheckPricingPlan="element.requirePricingPlan ?? []"
      [class.disabled]="!!element.disabled"
    >
      <div
        class="content"
        [appCheckAccess]="
          element.checkAccess ? [element.checkAccess[0], element.checkAccess[1]] : undefined
        "
        [routerLink]="element.path"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="$event.stopPropagation()"
      >
        <div class="icon-wrapper">
          <app-dynamic-host
            *ngIf="element.icon"
            [componentInputs]="{ color: 'var(--icon-color)' }"
            [componentType]="element.icon"
          ></app-dynamic-host>
        </div>

        <div class="text-wrapper text-16 fw-500" [class.with-action-button]="element.actionButton">
          {{ element.name | translate }}
          @if (element.actionButton) {
            <p-button
              class="action-button"
              [disabled]="
                element?.actionButton?.checkAccess
                  ? (accessControl.check$(
                      $any(element?.actionButton?.checkAccess?.[0]),
                      $any(element?.actionButton?.checkAccess?.[1])
                    ) | async) === false
                  : false
              "
              [pTooltip]="element.actionButton.name | translate"
              [tooltipPosition]="'top'"
              [routerLink]="element.actionButton.path"
              (click)="$event.stopPropagation()"
            >
              @if (element.actionButton.icon) {
                <app-dynamic-host
                  [componentInputs]="{ color: 'var(--icon-color)' }"
                  [componentType]="element.actionButton.icon"
                ></app-dynamic-host>
              }
            </p-button>
          }
        </div>

        @if (element.counterKey && ((badges.counters$ | async) || {})[element.counterKey]) {
          <p-badge
            [value]="((badges.counters$ | async) || {})[element.counterKey]"
            styleClass="counter-badge"
          />
        }

        <div (click)="toggleElementExpanded(element); $event.stopPropagation()" class="expand-icon">
          <app-icon-chevron
            [color]="'var(--icon-color)'"
            [direction]="element.isExpanded ? 'up' : 'down'"
          ></app-icon-chevron>
        </div>
      </div>

      <div *ngIf="element.children?.length" class="children">
        <div *ngFor="let child of element.children" class="navigation-item {{ child.cssClass }}">
          <div
            class="content"
            [appCheckAccess]="
              child.checkAccess ? [child.checkAccess[0], child.checkAccess[1]] : undefined
            "
            [routerLinkActive]="'active'"
            [routerLink]="child.path"
            (click)="$event.stopPropagation()"
          >
            <div *ngIf="child.icon" class="icon-wrapper">
              <app-dynamic-host
                *ngIf="child.icon"
                [componentInputs]="{ color: 'var(--icon-color)' }"
                [componentType]="child.icon"
              ></app-dynamic-host>
            </div>
            <div class="text-wrapper text-16 fw-500">
              {{ child.name | translate }}
            </div>
            @if (child.counterKey && ((badges.counters$ | async) || {})[child.counterKey]) {
              <p-badge
                [value]="((badges.counters$ | async) || {})[child.counterKey]"
                styleClass="counter-badge"
              />
            }
          </div>
        </div>
      </div>
    </div>
  }

  <div class="spacer"></div>

  @if (!deviceService.isPWA) {
    @if (deviceService.osName === 'iOS') {
      <div class="navigation-item install" (click)="pwaInstallRef.open()">
        <div class="content">
          <div class="icon-wrapper">
            <app-icon-download color="var(--icon-color)"></app-icon-download>
          </div>

          <div class="text-wrapper text-16 fw-500">{{ 'fleet.menu.install-app' | translate }}</div>
        </div>
      </div>
    } @else {
      @if (installPwaEvent$ | async) {
        <div class="navigation-item install" (click)="promptPWAInstall()">
          <div class="content">
            <div class="icon-wrapper">
              <app-icon-download color="var(--icon-color)"></app-icon-download>
            </div>

            <div class="text-wrapper text-16 fw-500">
              {{ 'fleet.menu.install-app' | translate }}
            </div>
          </div>
        </div>
      }
    }
  }

  <div class="navigation-item settings">
    <div
      [routerLinkActive]="'active'"
      [routerLink]="['settings']"
      class="content"
      (click)="$event.stopPropagation()"
    >
      <div class="icon-wrapper">
        <app-icon-settings [color]="'var(--icon-color)'"></app-icon-settings>
      </div>
      <div class="text-wrapper text-16 fw-500">
        {{ 'fleet.menu.settings' | translate }}
      </div>
      <div class="expand-icon"></div>
    </div>
  </div>
</section>

<div class="horizontal-spacer--24"></div>

<section class="footer">
  <div *ngIf="profile.state | async as user" class="personal-info">
    <app-avatar
      (click)="personalMenuRef.toggle($event)"
      [photo]="user.photo"
      class="mr-12 clickable"
    ></app-avatar>

    <div class="info-wrapper">
      <div class="name text-14 fw-600">{{ user.name }}</div>
      <div class="email text-14 fw-400">{{ user.email }}</div>
    </div>

    <app-icon-chevron
      (click)="personalMenuRef.toggle($event)"
      [color]="'var(--icon-color)'"
      [direction]="'down'"
      class="icon clickable"
    ></app-icon-chevron>
  </div>
  <div class="feedback-area">
    <app-feedback-button [isCompact]="collapsed" />
  </div>
</section>

<app-personal-menu
  #personalMenuRef
  (languageClicked)="langSwitchRef.open()"
  (settingsClicked)="userSettingsRef.open()"
></app-personal-menu>

<app-lang-switch-dialog #langSwitchRef></app-lang-switch-dialog>

<app-user-settings-dialog #userSettingsRef></app-user-settings-dialog>

<app-pwa-install-guide #pwaInstallRef></app-pwa-install-guide>
