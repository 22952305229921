import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { takeUntil } from 'rxjs'
import {
  BrowserSessionStorage,
  injectDestroy$,
  NavigationHistoryProvider,
} from '@ti-platform/web/common'
import { SidebarService } from '@ti-platform/web/ui-kit/layout'
import { FilterPanelService } from '@ti-platform/web/ui-kit/layout/components/filter-panel/filter-panel.service'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  protected readonly router = inject(Router)
  protected readonly logoService = inject(BrandingService)
  protected readonly sidebarService = inject(SidebarService)
  protected readonly filterPanelService = inject(FilterPanelService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly navigationHistoryProvider = inject(NavigationHistoryProvider)
  protected readonly destroy$ = injectDestroy$()

  public async ngOnInit() {
    if (!this.sessionStorage.getItem('sidebarCollapsed')) {
      if (window?.innerWidth < 1024) {
        this.sessionStorage.setItem('sidebarCollapsed', 'true')
        this.sidebarService.isCollapsed.set(true)
      } else {
        this.sessionStorage.setItem('sidebarCollapsed', 'false')
      }
    } else {
      this.sidebarService.isCollapsed.set(
        this.sessionStorage.getItem('sidebarCollapsed') === 'true',
      )
    }

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hideOpenedPanel()
      }
    })
  }

  public hideOpenedPanel() {
    if (!this.sidebarService.isHidden()) {
      this.sidebarService.hide()
    }
    this.filterPanelService.closePanel()
  }
}
