import { BaseConfig } from '@ti-platform/web/common'

export default {
  envType: 'dev',
  googleTagManagerId: 'G-NGME27F0DY',
  awsRegion: 'eu-central-1',
  // Default
  awsMapName: 'esri-navigation',
  awsMapApiKey:
    'v1.public.eyJqdGkiOiI0MTJlNmY1Zi0wNTBlLTQ4OTMtOThmYi03ZThjOTE0ODdmZWIifSNSZ9sL7yclYARbQvFflT_zs5rU19X4J8CAymzdC79jpz7WRASgnxNL8mlSpbc1VtKCfILlOOatb76s-FpUv2epGbERV4sWSdJgFRz5ptMRIB-4GXogQtMq3uHrDlVthQx2dYo0gWk1o87r6ciexountHAYLX-_UhrnLBHVoq_KLpc7kL-3-_wIRWtOoIj9uF6uM0WMcdpbJlx586yd0JHqTZiWCexgvmtOh-mtS1bmtJX2ZRQj5XCFX7HbmeuVLZSDxzRNjoSP5NFi48ttlBZfN0k6PKejR41y1U_OlZ8OmNguqSpPfd2jsXuwcnopatwfGcre-QG6fDHCGrNesXI.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy',

  // Esri Imagery
  esriSatelliteMapName: 'esri-imagery',
  esriSatelliteMapApiKey:
    'v1.public.eyJqdGkiOiIyMWEwYjdkMi0zYmI5LTRlZmUtOWZmNS1iOTRlNjQyNWEyNzYifTKeZUmsyKWE6ML6RFM0STqF0bXapRZhEG1UGz7wNbSOtBChCtm7TC7PJl0jLRY_BS8bdWQiHDCk_D2qw_lyjs3PuwhPmsC_LzOiqmxCRXbZJCB1b1xi0uHQG56WLByFzFQ4GM_v5n07PFzsszATxhULWBuAcdYtr42zfGvVkYN4uhDb9Ui-9G_S48cKGrNDmhXurxGxCQ2fXVWcg1-ryxtp6FlC8WU-Y64_TqBcyOlA_eYrRuoNDcQvsujvxEXrzz23LoHhGkATQQtZDwvEd-qF2A3yq3lbfWu5DIJFDq9o6SNi18pFLHXF9lFI_ZbONHfYvtS4BBvtOJSiQatnmgk.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy',

  // HERE Hybrid
  hereHybridMapName: 'here-hybrid',
  hereHybridMapApiKey:
    'v1.public.eyJqdGkiOiJjZmE5NjVkYy04OTk4LTQ0NDctODQ2My05ODUwN2EyZWRlMTIifWLblcLEGDe3H7VWVA8iqqvrMZoMyQw1n2XYS8KXvcpzhOrAs6tuHekff9-tp0ecEwSflhcYj-KA--Vcq7-s15l3KT08P7prTkgXO_lfwTmR2_VUJy9KdtagD3Jir8DSbjmUBAWTwBzRl_4nHcTYHuKnRzeCXiUmc06WJhcgAC-MRMK7lE8k0f8Bjkmh5NYD5v69BbxbQctFivq-9OORHyyKHSvxj6Lh_dwcOeti2tjy11N7tgTTpj7kDtIHIqPqdt7Dg7Q-qMMRd1LfRZgfGPjPEsiWgH-4Isl8QxC8dirvyeuVdZT4h9Uu2t2NGZTQxLTFs-vDBKYp2QmshhbsiaU.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy',

  // Esri Imagery
  hereSatelliteMapName: 'here-imagery',
  hereSatelliteMapApiKey:
    'v1.public.eyJqdGkiOiJjNzE5ZWMxMS0wYTZiLTQ3YWQtODk2Yi1kOTE3NzVlZWRkODUifU9keEcmpx2pnXCrQTAXLFa9G22UmvfFb0PRmsQIU2oXlz4EYRpjcMZQvzF2YAOBNSUXceSTcYg8xOf3N304QkMoVg1JeDSus4-ka1jj-zpQbJ1aoUfuhzbVBOqQnrW_iZi-vg43oQzJsw-H6nTmg3DsErFhcdFzhG2GYipZbExhLtHYx3gbbuqUbWb-iCZXBaKAqC8qVE8ITbaxFoyARV1WBJo5laqRUQ16FiVD7iCky_pgRIgfN_0v17HshxaAcAwtXjhmHnMO6tkLeWTqkletBDNhghXhy0T_9Rmz2cG-ZFvmiEz-CETxhQ3m4pnDUbEWCvgFK4y7yBi4Q1JN1dw.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy',

  mapTilerApiKey: 'rnAyG1XTcUMVHIefbAsp',
  mapTilerStyleName: 'streets-v2-light',
  apiUrl: 'https://wz7yvu5hwk5d3uzsurjtywnbtu0phaun.lambda-url.eu-central-1.on.aws',
  flespiUrl: 'https://flespi.io',
  tspDefaultDomain: 'admin-dev.telematicsinsights.ai',
  fleetDefaultDomain: 'dev.telematicsinsights.ai',
  sentry: {
    enabled: true,
    dsn: 'https://08a58a5aba9c60e954ce5f9f6f2b24f4@o4507342474313728.ingest.us.sentry.io/4507345794170880',
    tracePropagationTargets: [
      'localhost',
      'http://localhost:4200',
      'https://dev.telematicsinsights.ai',
      'https://wz7yvu5hwk5d3uzsurjtywnbtu0phaun.lambda-url.eu-central-1.on.aws',
    ],
  },
  push: {
    instanceId: 'c5c25d94-f070-495f-bb59-92e1a60263cd',
  },
  amplify: {
    userPoolId: 'eu-central-1_wTjZAm1uk',
    identityPoolId: 'eu-central-1:c4a957ce-4a65-4d74-b443-368b1fa47e04',
    userPoolClientId: '7sas0rf8310n2i7u13n4hj1eiu',
  },
  firebase: {
    apiKey: 'AIzaSyCXctdoBPvv0vy4lGYaQc6aJZamMr6gxj4',
    authDomain: 'ti-dev-709fd.firebaseapp.com',
    projectId: 'ti-dev-709fd',
    storageBucket: 'ti-dev-709fd.appspot.com',
    messagingSenderId: '791311709398',
    appId: '1:791311709398:web:532c81a8396a953b4d4577',
    measurementId: 'G-KXW4MQVG5V',
  },
  fcmVapidKey:
    'BEmOEdWkvh965XPyoRG8pF65tyjVsN6zM9hHs0bI-_X_XRuJA6OhkUf0DxK2y6tVxjcQ9Vsx1SZbePgKPwz_Z-8',
} as BaseConfig
