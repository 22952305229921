{
  "primeng": {
    "dayNames": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "dayNamesShort": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "dayNamesMin": [
      "Su",
      "Mo",
      "Tu",
      "We",
      "Th",
      "Fr",
      "Sa"
    ],
    "monthNames": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "monthNamesShort": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "today": "Today",
    "weekHeader": "Wk"
  },
  "common": {
    "yes": "Yes",
    "no": "No",
    "ok": "Ok",
    "yes-delete": "Yes, delete",
    "yes-remove": "Yes, remove",
    "no-results": "No results found",
    "on": "On",
    "off": "Off",
    "edit": "Edit",
    "enable": "Enable",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "download": "Download",
    "disable": "Disable",
    "please-try-again-later": "Please try again later.",
    "please-check-entered-data": "Please check entered data",
    "do-not-show-again": "Do not show again",
    "copy": "Copy",
    "copied": "Copied!",
    "done": "Done",
    "loading": "Loading",
    "open": "Open",
    "select-date": "Select date"
  },
  "countries": {
    "AR": "Argentina",
    "BZ": "Belize",
    "BO": "Bolivia",
    "BR": "Brazil",
    "CA": "Canada",
    "CL": "Chile",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "SV": "El Salvador",
    "GT": "Guatemala",
    "HN": "Honduras",
    "JM": "Jamaica",
    "MX": "Mexico",
    "NI": "Nicaragua",
    "PA": "Panama",
    "PY": "Paraguay",
    "PE": "Peru",
    "TT": "Trinidad and Tobago",
    "US": "United States",
    "UY": "Uruguay",
    "VE": "Venezuela"
  },
  "measurement-system": {
    "miles": "Miles",
    "kilometers": "Kilometers",
    "gallons": "Gallons",
    "liters": "Liters",
    "mpg": "MPG",
    "lkm": "L/100km",
    "km-gal": "km/gal",
    "fahrenheit": "Fahrenheit",
    "celsius": "Celsius"
  },
  "button": {
    "load-more": "Load more",
    "search": "Search",
    "show-more": "Show more",
    "sign-out": "Sign out",
    "reset": "Reset",
    "save": "Save",
    "save-changes": "Save changes",
    "cancel": "Cancel",
    "clear-all": "Clear all",
    "add": "Add",
    "back": "Back",
    "change": "Change",
    "close": "Close",
    "confirm": "Confirm",
    "remove": "Remove",
    "delete": "Delete",
    "upload": "Upload",
    "update": "Update",
    "replace": "Replace",
    "launch": "Launch",
    "apply": "Apply",
    "export": "Export",
    "filters": "Filters",
    "continue": "Continue",
    "abort-process": "Abort process"
  },
  "date-range": {
    "today": "Today",
    "yesterday": "Yesterday",
    "last-7-days": "Last 7 days",
    "last-30-days": "Last 30 days"
  },
  "feedback": {
    "title": "Give feedback",
    "cancel-button": "Cancel",
    "submit-button": "Send report",
    "confirm-button": "Confirm",
    "add-screenshot-button": "Add screenshot",
    "remove-screenshot-button": "Remove screenshot",
    "name-label": "Name",
    "name-placeholder": "Your name",
    "email-label": "Email",
    "email-placeholder": "Your email",
    "message-label": "Description",
    "message-placeholder": "What's the bug? What did you expect?",
    "success-message-text": "Thank you for your report"
  },
  "auth": {
    "error": {
      "email-is-required": "Email is required",
      "email-is-not-valid": "Email is not valid",
      "current-password-is-required": "Please enter current password",
      "passwords-are-not-equal": "Passwords in both fields must match",
      "password-is-too-short": "Must be at least 8 characters long",
      "password-must-contain-numeric": "Must contain at least one number",
      "password-must-contain-uppercase": "Must contain at least one capital letter",
      "new-password-equal-previous": "Must be different from previously used"
    },
    "label": {
      "password-requirements": "Password requirements:"
    },
    "create-password": {
      "title": "Create your password",
      "subtitle": "Enter password below",
      "pass-placeholder": "Create password",
      "pass-repeat-placeholder": "Confirm password"
    },
    "create-password-success": {
      "title": "Password created",
      "subtitle": "Your password has been successfully created",
      "button": "Log into app"
    },
    "change-password": {
      "title": "Change your password",
      "subtitle": "Enter password below",
      "curr-placeholder": "Enter current password",
      "pass-placeholder": "Enter new password",
      "pass-repeat-placeholder": "Confirm new password"
    },
    "change-password-success": {
      "title": "Password changed",
      "subtitle": "Your password has been successfully changed.",
      "button": "Back to app"
    },
    "reset-password": {
      "title": "Create new password",
      "subtitle": "Enter password below",
      "pass-placeholder": "Create password",
      "pass-repeat-placeholder": "Confirm password"
    },
    "reset-password-success": {
      "title": "Password created",
      "subtitle": "Your password has been successfully created.",
      "button": "Log into app"
    },
    "forgot-password": {
      "title": "Forgot password",
      "subtitle": "Enter your email to initiate password reset."
    },
    "forgot-password-success": {
      "title": "Password reset initiated",
      "subtitle": "If the email address you entered exists in our system, you will receive a message with further instructions."
    }
  },
  "bookmarks": {
    "title": "Bookmarks",
    "description": "All of your bookmarks are stored here for quick access. You can bookmark any vehicle, triggered alert, media item, or report to easily find them later.",
    "filter-placeholder": "Select filter",
    "no-data-description": "No bookmarks have yet been added.",
    "actions": {
      "add": "Bookmark",
      "remove": "Remove bookmark",
      "remove-description": "This action does not change the item. It only removes it from the Bookmarks page.",
      "remove-confirmation": "Are you sure you want to remove “{name}” bookmark?",
      "remove-btn": "Yes, remove",
      "rename": "Rename",
      "rename-title": "Rename bookmark",
      "rename-description": "Give your bookmark a new name to keep your saved links organized and easy to find."
    },
    "labels": {
      "name": "Name",
      "type": "Type",
      "types": "Types",
      "added": "Added"
    },
    "types": {
      "vehicle-details": "Vehicle details",
      "triggered-alert": "Triggered alert",
      "media-group": "Media item",
      "report": "Report"
    }
  },
  "fleet": {
    "heading": "Fleet",
    "header-vehicles": "Vehicles",
    "view-details": "View details",
    "last-reported": "Last reported",
    "trips": "Trips",
    "map": "Map",
    "badge": {
      "moving": "Moving",
      "stopped": "Stopped",
      "idling": "Idling"
    },
    "button": {
      "edit-vehicle": "Edit vehicle",
      "request-video": "Request video",
      "view-live-stream": "View live stream",
      "get-realtime-photo": "Get real-time photo",
      "camera-offline": "Camera offline"
    },
    "indicator": {
      "ignition": "Ignition",
      "fuel-level": "Fuel level",
      "odometer": "Odometer",
      "engine-hours": "Engine hours",
      "battery": "Vehicle battery"
    },
    "sort": {
      "name-asc": "Name, A-Z",
      "name-desc": "Name, Z-A",
      "moving-desc": "Moving, recent first",
      "stopped-desc": "Stopped, longest first",
      "idling-desc": "Idling, longest first"
    },
    "status": {
      "on": "ON",
      "off": "OFF",
      "na": "N/A"
    },
    "message": {
      "copy-coordinates": "Copy coordinates",
      "coordinates-copied": "Coordinates copied to clipboard",
      "may-not-be-current": "may not be current",
      "no-data-yet": "No data yet",
      "no-history-yet": "No history yet",
      "no-commands-set-up": "No commands set up.",
      "command-execution-success": "Command “{name}” sent successfully",
      "command-execution-failure": "Command “{name}” failed to send"
    },
    "menu": {
      "bookmarks": "Bookmarks",
      "dashboard": "Dashboard",
      "fleet": "Fleet",
      "alters": "Alerts",
      "locations": "Locations",
      "alerts_setup": "Set up alert",
      "alerts_triggered": "Triggered alerts",
      "alerts_created": "Manage alerts",
      "video": "Video",
      "video_request": "Request video",
      "video_library": "Media library",
      "video_stream": "Live stream",
      "dispatch": "Dispatch",
      "maintenance": "Maintenance",
      "maintenance_overdue": "Overdue and upcoming",
      "maintenance_status": "Maintenance status",
      "maintenance_reports": "Inspection reports",
      "maintenance_services": "Services performed",
      "maintenance_schedules": "Schedules",
      "chat": "Chat with drivers",
      "reports": "Reports",
      "settings": "Settings",
      "feedback": "Feedback",
      "language": "Language",
      "install-app": "Install App"
    },
    "vehicles-list": {
      "no-data-title": "No items to display",
      "no-data-description": "No vehicles have yet been added."
    }
  },
  "locations": {
    "title": "Locations",
    "map-view": "Map view",
    "controls": {
      "add-new": "Add location",
      "cancel-add": "Cancel adding location",
      "cancel-edit": "Cancel editing location"
    },
    "grid": {
      "name": "Name",
      "type": "Type",
      "address": "Address",
      "type-address": "Address",
      "type-polygon": "Geofence Polygon",
      "type-circle": "Geofence Circle",
      "no-data-title": "No items to display",
      "no-data-description": "No locations have yet been added."
    },
    "filter": {
      "type": {
        "label": "Type",
        "placeholder": "Select filter",
        "option": {
          "address": "Address",
          "circle": "Circle",
          "polygon": "Polygon"
        }
      }
    },
    "delete-dialog": {
      "summary": "Are you sure you want to delete geofence “{name}”?",
      "description": "The geofence will be deleted permanently."
    },
    "address-created-message": {
      "detail": "Address saved to Locations successfully"
    },
    "geofence-created-message": {
      "detail": "Geofence saved to Locations successfully"
    },
    "geofence-deleted-message": {
      "detail": "Geofence deleted successfully"
    }
  },
  "video": {
    "common": {
      "show": "Show",
      "hide": "Hide",
      "view-alert": "View alert"
    },
    "media-library": {
      "title": "Media library",
      "menu": {
        "mark-as-new": "Mark as New",
        "retrieved": "Retrieved",
        "requested": "Requested",
        "failed": "Failed",
        "cancel-retrieval": "Cancel retrieval",
        "retry-failed-retrieval": "Retry failed retrieval",
        "remove-unretrieved": "Remove unretrieved",
        "lock-media": "Lock media",
        "unlock-media": "Unlock media"
      },
      "lock-tooltip": "Media is locked and won't be deleted on rotation.",
      "retrieval-failed": "Video retrieval failed",
      "request-again": "Request again",
      "pending-retrieval": "Pending retrieval",
      "no-data-title": "No media to display",
      "no-data-description": "No media have yet been requested",
      "some-time-to-retrieve": "The video may take some time to retrieve.",
      "grid": {
        "recorded": "Recorded",
        "new": "New",
        "vehicle": "Vehicle",
        "retrieved": "Retrieved",
        "alert-trigger": "Alert trigger"
      },
      "lock-exceeded": {
        "summary": "Media item cannot be locked",
        "description": "Over 80% of items in your media storage is currently locked. To lock additional items, you must first free up space by deleting or unlocking some of your locked media, or by requesting a higher storage limit."
      }
    },
    "request": {
      "title": "Request video",
      "description": "Retrieve camera footage from the video telematics device.",
      "select-vehicle": "Select vehicle",
      "request-mode": "Request mode",
      "playback-mode": "Playback mode",
      "historical-video-playback": "Historical video playback",
      "set-custom-duration": "Set custom duration",
      "trips": "Trips",
      "request-video": "Request video",
      "select-preset": "Select a preset duration from the dropdown menu, or use",
      "handlers-timeline": "handles on the timeline to set a custom duration.",
      "cameras": "Cameras",
      "video": "Video",
      "video-source": "Choose a video source:",
      "video-has-been-requested": "Video has been requested",
      "retrieving-video": "Retrieving your video(s) from the device may take some time. Once ready, you'll find it in the Media library.",
      "retrieving-video-offline": "Because the camera is offline, retrieval is not guaranteed.",
      "retrieving-video-offline-2": "You can monitor the status of your request in the Media Library under the 'Pending' section",
      "go-to-library": "Go to Media library",
      "video-quality": "Video quality",
      "camera-is-offline": "Camera is offline",
      "retrieval-not-guaranteed": "Please note that retrieval may not be guaranteed.",
      "monitor-status": "You can monitor the status of your request in the",
      "pending-section": "under the 'Pending' section",
      "media-library": "Media Library",
      "request-alert-video": "Request alert video",
      "custom-duration": "interface to specify a custom duration.",
      "before-and-after": "secs before and after",
      "retrieving-message": "Retrieving...",
      "search-vehicle": "Search vehicle",
      "no-recorded-activity": "No recorded activity",
      "video-quality-high": "High",
      "video-quality-standard": "Standard",
      "errors": {
        "duration-too-short": "Minimum duration is 5 seconds.",
        "duration-too-long": "Maximum duration is 5 minutes."
      },
      "mobile-flow": {
        "retrieve-camera-footage": "Retrieve camera footage",
        "access-video-linked": "Access videos linked to triggered alerts or request footage for a specific date and time.",
        "video-linked-header": "Video linked to an alert",
        "camera-offline-panel": "Camera offline. All alerts are shown; some may lack video and retrieval can’t be guaranteed.",
        "alerts-displayed": "The alerts displayed below have been filtered to show only those associated with available video footage.",
        "specific-date-time": "Video for a specific date and time ",
        "desired-date": "Choose desired date and time to request the video.",
        "choose-an-alert": "Choose an alert to proceed with your video request.",
        "linked-to-an-alert": "Linked to an alert ",
        "retrieve-video-recorded": "Retrieve video recorded shortly before and after an alert was triggered.",
        "based-on-specific-date": "Based on a specific date and time",
        "choose-the-date-and-time": "Choose the date and time to retrieve any recorded video footage.",
        "select-preset-custom": "Select a preset duration from the dropdown menu, or set custom duration.",
        "select-preset-alert": "Select a preset duration from the dropdown menu.",
        "set-custom-duration": "Set custom duration",
        "enter-video-duration": "Enter the desired video duration below (minimum: 5 seconds, maximum: {maxVal} minutes).",
        "duration": "Duration",
        "specify-time": "Specify time",
        "custom-date": "Custom date",
        "request-custom-video": "Request custom video",
        "select-parameters": "Select the parameters needed to request the video."
      }
    },
    "live-stream": {
      "live-stream": "Live stream",
      "initiate-view": "Initiate and view live video stream from the vehicle.",
      "still-watching-question": "Are you still watching?",
      "check": "This check is necessary to prevent excessive use of cellular traffic.",
      "stop-stream": "Stop stream",
      "exceeded": {
        "summary": "No streaming minutes left",
        "description": "You have used all the video streaming minutes available for this vehicle."
      },
      "errors": {
        "unable-to-start": {
          "summary": "Unable to start live stream",
          "detail": "Please try again later."
        }
      }
    },
    "full-screen-dialog": {
      "review-video": "Review video",
      "review-photo": "Review photo",
      "view-alert": "View alert",
      "previous": "Previous",
      "next": "Next",
      "close-full-screen": "Close full screen view",
      "download-video": "Download video",
      "download-photo": "Download photo"
    },
    "playback": {
      "photo": "Photo",
      "real-time-photo": "Real-time photo",
      "video-playback": "Video playback",
      "live-stream": "Live stream",
      "live-video": "Live video stream",
      "live-video-footage": "View live footage to validate real-time events and ensure accountability.",
      "photo-description": "Review and analyze photo to validate events and ensure accountability.",
      "video-description": "Review and analyze recorded footage to validate events and ensure accountability.",
      "real-time-photo-description": "Review and analyze real-time photo to validate events and ensure accountability.",
      "clip-video": "Clip video",
      "customize-duration": "Customize duration and your other preferred setting.",
      "quality": "Video quality",
      "video": "Video",
      "cameras": "Cameras",
      "choose-video-source": "Choose a video source",
      "request-video": "Request video",
      "playback-settings": "Playback settings",
      "preferred-settings": "Customize your preferred settings.",
      "errors": {
        "unable-to-start": {
          "summary": "Unable to start playback",
          "detail": "Please try again later."
        }
      }
    },
    "filter": {
      "type": {
        "label": "Media type",
        "placeholder": "Select filter",
        "option": {
          "video": "Video",
          "photo": "Photo"
        }
      },
      "status": {
        "label": "Status",
        "placeholder": "Select filter",
        "option": {
          "new": "New",
          "viewed": "Viewed",
          "locked": "Locked",
          "pending": "Pending"
        }
      },
      "alert-trigger": {
        "label": "Alert trigger",
        "placeholder": "",
        "option": {
          "not-linked-to": "Not linked to alert",
          "unknown": "Unknown",
          "no-dispatch": "No dispatch"
        }
      },
      "vehicles": {
        "label": "Vehicles"
      }
    },
    "dialog": {
      "summary": "Are you sure what you want to delete?",
      "description": "The video/photo will be deleted permanently",
      "download-title": "Getting your files ready",
      "download-summary": "Your media files are currently being prepared for download."
    }
  },
  "reports": {
    "title": "Reports",
    "export": {
      "pagination": "Page --pageNumber-- of --totalPages--",
      "grid": {
        "distance": "Distance driven ({distance})",
        "time-moving": "Time moving",
        "activity-speed": "Speed ({speed})",
        "activity-ignition": "Ignition",
        "activity-odometer": "Odometer ({distance})",
        "activity-battery": "Vehicle battery (V)",
        "last-vehicle-battery": "Vehicle battery (V)",
        "trip-distance": "Distance ({distance})",
        "duration": "Duration",
        "trip-max-speed": "Maximum speed ({speed})",
        "trip-avg-speed": "Average speed ({speed})",
        "total-distance": "Total distance ({distance})",
        "total-driving-time": "Total driving time",
        "total-time-stopped": "Total time stopped",
        "no-data": "No data available",
        "total-idling-duration": "Total idling duration",
        "stops-with-idling": "Stops with idling",
        "average-idling-duration": "Average idling duration",
        "total-idle-time": "Total idle time",
        "average-idle-time": "Average idle time",
        "idling-duration": "Idling duration",
        "trigger": "Trigger",
        "number-of-times": "Number of times"
      }
    },
    "descriptions": {
      "driving-activity-short": "Driving activity",
      "driving-activity-long": "Review cumulative and detailed driving activity by vehicle.",
      "trip-history-short": "Trip history",
      "trip-history-long": "Review trips taken by vehicles.",
      "last-active-short": "Last active",
      "last-active-long": "Identify vehicles that haven’t been used or devices that haven’t communicated for a while.",
      "stop-history-short": "Stop history",
      "stop-history-long": "Review stops made by vehicles.",
      "visited-locations-short": "Visited locations",
      "visited-locations-long": "Monitor where your fleet’s non-driving time is spent.",
      "safety-incidents-short": "Safety incidents",
      "safety-incidents-long": "Review and resolve safety incidents with your drivers.",
      "engine-idling-short": "Engine idling",
      "engine-idling-long": "Reduce fuel wasted by monitoring idling - time spent with ignition on while not moving.",
      "choose-a-view": "Choose a view"
    },
    "map-view": "Map view",
    "grid": {
      "vehicle": "Vehicle",
      "trip": "Trip",
      "distance": "Distance driven",
      "time-moving": "Time moving",
      "stops": "Stops",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "begin-address": "Begin address",
      "end-address": "End address",
      "start-timestamp": "Start time",
      "begin-timestamp": "Begin time",
      "end-timestamp": "End time",
      "activity-date": "Time/Date",
      "activity-coordinates": "Coordinates",
      "activity-address": "Address",
      "activity-speed": "Speed",
      "activity-ignition": "Ignition",
      "activity-odometer": "Odometer",
      "activity-battery": "Vehicle battery",
      "last-communicated": "Last communicated",
      "last-reported-location": "Last reported location",
      "last-vehicle-battery": "Vehicle battery",
      "trip-distance": "Distance",
      "duration": "Duration",
      "trip-max-speed": "Maximum speed",
      "trip-avg-speed": "Average speed",
      "total-distance": "Total distance",
      "total-driving-time": "Total driving time",
      "begin": "Begin",
      "end": "End",
      "location": "Location",
      "total-stops": "Total stops",
      "total-time-stopped": "Total time stopped",
      "no-data": "No data available",
      "total-idling-duration": "Total idling duration",
      "stops-with-idling": "Stops with idling",
      "average-idling-duration": "Average idling duration",
      "total-idle-time": "Total idle time",
      "average-idle-time": "Average idle time",
      "idling-duration": "Idling duration",
      "date-and-time": "Date and time",
      "status": "Status",
      "incident": "Incident",
      "sos-alarm-events": "SOS / Alarm events",
      "speedings": "Speedings",
      "total-events": "Total events",
      "number-of-incidents": "Number of incidents",
      "known": "Known",
      "times-visited": "Times visited",
      "total-time": "Total time",
      "average-time-per-stop": "Average time per stop",
      "arrival": "Arrival",
      "departure": "Departure",
      "location-visited": "Location visited",
      "time-at-known-locations": "Time at known locations",
      "time-at-unknown-locations": "Time at unknown locations",
      "location-status": "Location status"
    },
    "filter": {
      "vehicles-title": "Vehicles",
      "vehicles-placeholder": "Select vehicles"
    },
    "tooltips": {
      "idling-min-duration": {
        "editable": "Minimum idling threshold. Idle durations below this limit won't be included in the report. Click on the value to change.",
        "not-editable": "Minimum idling threshold. Idle durations below this limit won't be included in the report."
      },
      "visited-locations": {
        "known-column": "Places saved in Locations are labeled as 'Known.' All other places are labeled as 'Unknown'.",
        "unknown": "Click the pin to save this place to 'Locations', making it 'Known'."
      }
    },
    "views": {
      "vehicles": "Vehicles",
      "incidents": "Incidents",
      "locations": "Locations"
    },
    "safety-incidents": {
      "no-incidents": {
        "summary": "No incidents  to display",
        "description": "No incidents have been captured."
      },
      "view-alert": "View alert",
      "false-positive-dialog": {
        "summary": "Incident removal",
        "description": "Marking this incident as a \"False positive\" will remove it from the Safety incidents report. Do you want to proceed?"
      }
    },
    "visited-locations": {
      "known": "Known",
      "unknown": "Unknown",
      "known-location": "Known location",
      "unknown-location": "Unknown location",
      "manage-locations": "Manage locations"
    }
  },
  "map": {
    "geofence-form": {
      "name": "Name",
      "create": "Create geofence",
      "select-type": "Select type",
      "save-and-setup-alert": "Save and Set up alert",
      "pin-tooltip": "Click to save to Locations",
      "errors": {
        "name-required": "Name is required",
        "name-not-allowed": "Name is already used"
      }
    },
    "tooltips": {
      "see-all-vehicles": "See all vehicles",
      "go-to-homebase": "Go to Homebase",
      "homebase-address-invalid": "Homebase address could not be found. Update in Settings.",
      "reset-the-map": "Reset the map",
      "alert-triggered": "Alert triggered",
      "alert-ended": "Alert ended"
    },
    "address-search": {
      "placeholder": "Search address"
    },
    "guide": {
      "add-location": {
        "title": "Adding location",
        "items": {
          "0": "To add a location, enter an address or right-click directly on the map."
        },
        "mobile-items": {
          "0": "To add a location, search for an address or press and hold anywhere on the map."
        }
      },
      "edit-geofence": {
        "title": "Edit geofence",
        "items": {
          "0": "To edit an existing geofence, double-click on it."
        },
        "mobile-items": {
          "0": "To edit an existing geofence, press and hold on it."
        }
      },
      "saving-address": {
        "title": "Saving address",
        "items": {
          "0": "Click the pin to save the address to Locations."
        },
        "mobile-items": {
          "0": "Tap the pin to save the address to Locations"
        }
      },
      "adding-circle-geofence": {
        "title": "Creating circle geofence",
        "items": {
          "0": "Click and drag any dot to resize the geofence."
        },
        "mobile-items": {
          "0": "Press and drag any dot to resize the geofence."
        }
      },
      "editing-circle-geofence": {
        "title": "Editing circle geofence",
        "items": {
          "0": "Click and drag any dot to resize the geofence."
        },
        "mobile-items": {
          "0": "Press and drag any dot to resize the geofence."
        }
      },
      "adding-polygon-geofence": {
        "title": "Creating polygon geofence",
        "items": {
          "0": "Move a point by clicking and holding it.",
          "1": "To delete a point, double-click on it."
        },
        "mobile-items": {
          "0": "Move a point by pressing and holding it while dragging.",
          "1": "Delete a point by pressing and holding it."
        }
      },
      "editing-polygon-geofence": {
        "title": "Editing polygon geofence",
        "items": {
          "0": "Move a point by clicking and holding it.",
          "1": "Delete a point by double-clicking on it."
        },
        "mobile-items": {
          "0": "Move a point by pressing and holding it while dragging.",
          "1": "Delete a point by pressing and holding it."
        }
      },
      "geofence-toggle-intro": {
        "title": "This toggle shows / hides geofences",
        "items": {
          "0": "To create geofence, right click anywhere on the map.",
          "1": "To edit an existing geofence, double-click on it."
        },
        "mobile-items": {
          "0": "To create geofence, press and hold anywhere on the map.",
          "1": "To edit an existing geofence, press and hold on it."
        }
      }
    },
    "tiles": {
      "aws-maps": "Standard",
      "here-hybrid": "Satellite",
      "google-maps": "Google Maps",
      "google-maps-satellite": "Google Maps (Satellite)",
      "maptiler": "MapTiler",
      "open-street-maps": "Open Street Maps",
      "esri-imagery": "Esri Imagery",
      "here-imagery": "HERE Imagery"
    }
  },
  "errors": {
    "unknown": "Unknown error.",
    "auth-user-invite-expired": "The invite has expired.",
    "auth-user-invite-timeout": "You need to wait at least 5 minutes before resending an invitation email.",
    "auth-user-password-invalid": "The entered password is not valid.",
    "auth-reset-password-expired": "The reset password request is expired.",
    "auth-reset-password-timeout": "You can send the reset password email once every 5 minutes.",
    "auth-user-with-same-email-exists": "User email provided is associated with an existing user.",
    "such-identifier-and-protocol-already-exists": "Device with such IMEI / Serial and the same protocol already exists.",
    "demo-fleet-limit-devices-reached": "{fleetName} is a Demo account. You've reached the maximum number of devices you can assign to this Demo account.",
    "identifier-not-match-pattern": "IMEI / Serial doesn't match pattern.",
    "sim-not-match-pattern": "Phone / ICCID doesn't match pattern.",
    "device-not-found": "Device was not found.",
    "device-connected-to-another-tsp": "Device is connected to another TSP.",
    "fleet-not-found": "Fleet was not found.",
    "fleet-connected-to-another-tsp": "Fleet is connected to another TSP.",
    "video-devices-needs-video-channels": "You should indicate video channels number for video devices.",
    "only-video-device-may-have-video-channels": "You can indicate video channels number only for video devices.",
    "identifier-required-on-change-device-type": "You should indicate device IMEI / Serial on change device model.",
    "invalid-command-config-via-schema": "The command data does not match the command scheme.",
    "device-is-not-assigned": "Device is not assigned",
    "device-is-already-assigned": "Device is already assigned",
    "media-config-storage-capacity-little-for-locked-files": "With the newly selected storage capacity, locked media items would occupy more than 80% of the space, which is not permitted.",
    "live-stream-duration-is-exceed": "Video stream duration is exceeded",
    "playback-video-duration-is-exceeded": "Playback video duration is exceeded",
    "maximum-date-range-days": "The maximum allowed date range for selection is {days} days",
    "playback-video-did-not-start": "Video playback didn't start"
  },
  "search": {
    "no-data-title": "No data available",
    "no-data-description": "No results found for your search."
  },
  "alerts": {
    "manage": {
      "title": "Manage alerts",
      "grid": {
        "active": "Active",
        "name": "Name",
        "triggers": "Triggers",
        "vehicles": "Vehicles",
        "recipients": "Recipients",
        "notifications": "Notifications"
      },
      "no-data": {
        "title": "No items to display",
        "description": "No alerts have yet been set up."
      },
      "delete": {
        "dialog": {
          "summary": "Are you sure you want to delete \"{alertName}\"?",
          "description": "This alert will be permanently deleted. All related triggered alerts will not be deleted.",
          "confirm-button": "Yes, delete",
          "cancel-button": "Cancel"
        },
        "success": "Alert \"{alertName}\" was successfully deleted."
      }
    },
    "set-up": {
      "title": "Set up alert",
      "edit-title": "Edit alert",
      "step-number": "Step {number}",
      "trigger": "Trigger",
      "trigger-step-subheader": "What should trigger this alert?",
      "select-trigger": "Select a trigger",
      "geofence": "Geofence",
      "speed-limit": "Speed limit",
      "enter-speed": "Enter speed",
      "min-duration": "Minimum duration",
      "sec": "sec.",
      "mins": "mins.",
      "enter-duration": "Enter duration",
      "ignition-state": "Ignition state",
      "alert-name": "Alert name",
      "alert-name-hint": "Populated automatically. Edit as needed.",
      "next": "Next",
      "back": "Back",
      "select-option": "Select option",
      "select-geofence": "Select geofence",
      "harsh-events": "Harsh events",
      "min-duration-tooltips": {
        "speeding": "Alert won't trigger if speeding is shorter than this value.",
        "engine-idling": "Alert won't trigger if idling is shorter than this value.",
        "geofence-activity": "Alert won't trigger if vehicle enters/exits geofence for less time than this value.",
        "movement": "Alert won't trigger if movement state is shorter than this value.",
        "ignition": "Alert won't trigger if ignition state is shorter than this value.",
        "towing": "Alert won't trigger if towing is shorter than this value."
      },
      "vehicles-step-subheader": "What vehicle(s) is this alert for?",
      "select-vehicle": "Select vehicle",
      "all": "All",
      "all-vehicles-tooltip": "This selection will apply the alert to all current and future vehicles.",
      "specific": "Specific",
      "recipients-step-subheader": "Who should get notified about this alert?",
      "select-recipients": "Select recipient(s)",
      "add-recipient": "Add new recipient",
      "enter-email": "Enter email",
      "notifications-step-subheader-last": "How should recipients be notified?",
      "notifications-step-subheader-united": "Who should get notified and how?",
      "actions-step-subheader": "Should any actions be executed automatically?",
      "other-options": "Other options",
      "repeat-notification": "Repeat notification",
      "repeat": {
        "speeding": {
          "tooltip": "Enable this option to send notifications at regular intervals if speeding continues.",
          "text": "If speeding is ongoing, notify every"
        },
        "towing": {
          "tooltip": "Enable this option to send notifications at regular intervals if towing continues.",
          "text": "If towing is ongoing, notify every"
        },
        "engine-idling": {
          "tooltip": "Enable this option to send notifications at regular intervals if idling continues.",
          "text": "If idling is ongoing, notify every"
        }
      },
      "close-dialog": {
        "summary": "Are you sure you want to close?",
        "description": "The progress will be lost.",
        "confirm-button": "Yes, close"
      },
      "success-add": "Alert has been set up successfully",
      "success-edit": "Alert has been updated successfully",
      "do-not-notify": {
        "label": "Do not notify",
        "tooltip": "When triggered, alerts will appear in the 'Triggered Alerts' section without generating a notification."
      },
      "types": "Types"
    },
    "triggered": {
      "title": "Triggered alerts",
      "grid": {
        "name": "Name",
        "type": "Type",
        "vehicle": "Vehicle",
        "date-time": "Date / Time",
        "location": "Location",
        "status": "Status"
      },
      "no-data": {
        "title": "No items to display",
        "description": "No triggered alerts found for the date selected."
      },
      "view": {
        "change-to-unreviewed": "Change to Unreviewed",
        "change-to-false-positive": "Change to False positive",
        "check-as-false-positive": "False positive",
        "mark-as-reviewed": "Mark as Reviewed",
        "change-to-reviewed": "Change to Reviewed",
        "audit-log": {
          "title": "Audit log"
        },
        "modify-alert": {
          "title": "Modify alert settings",
          "summary": "Source alert was deleted and therefore cannot be modified",
          "description": "You can set up a new alert."
        },
        "confirm": "Confirm",
        "comment": "Comment (optional)",
        "enter-comment": "Enter a comment...",
        "ongoing": "Ongoing",
        "duration": "Duration",
        "triggered-at": "Triggered at",
        "max-speed": "Max speed",
        "over": "Over",
        "overMinutes": "Over {value} min",
        "distance": "Distance",
        "geofence-name": "Geofence name"
      }
    },
    "filters": {
      "title": "Filters",
      "select-triggers": "Select triggers",
      "select-types": "Select types",
      "select-statuses": "Select statuses"
    },
    "triggers": {
      "title": "Triggers",
      "types": {
        "speeding": {
          "title": "Speeding",
          "description": "Over",
          "details": "Alert triggers on exceeding user-defined speed limit."
        },
        "movement": {
          "title": "Movement",
          "start": "Start",
          "stop": "Stop",
          "details": "Alert triggers when speed changes from zero to >0 or vice versa."
        },
        "sos-alarm": {
          "title": "SOS / Alarm",
          "details": "Alert triggers on SOS or Alarm button press."
        },
        "geofence-activity": {
          "title": "Geofence activity",
          "entry": "Entry",
          "exit": "Exit",
          "details": "Alert triggers when vehicle enters or exits from the selected geofence(s)."
        },
        "towing": {
          "title": "Towing",
          "details": "Alert triggers when towing is detected (vehicle movement while ignition is off)."
        },
        "engine-idling": {
          "title": "Engine idling",
          "description": "Over {idlingMinutes} min",
          "details": "Alert triggers when engine idling (ignition is on with no movement) lasts longer than minimum amount of time defined."
        },
        "ignition": {
          "title": "Ignition",
          "on": "ON",
          "off": "OFF",
          "details": "Alert triggers when engine ignition state changes from on to off or vice versa."
        },
        "harsh-driving": {
          "title": "Harsh driving",
          "acceleration": "Acceleration",
          "braking": "Braking",
          "cornering": "Cornering",
          "details": "Alert triggers when harsh acceleration, braking or cornering is detected."
        },
        "safety-ai-events": {
          "title": "Safety AI events",
          "single-title": "Safety AI event",
          "fatigue": "Fatigue",
          "smoking": "Smoking",
          "distraction": "Distraction",
          "no-seat-belt": "No seat belt",
          "phone-use": "Phone use",
          "collision-warning": "Collision warning",
          "lane-departure-warning": "Lane departure warning",
          "tailgating": "Tailgating",
          "pedestrian-warning": "Pedestrian warning",
          "details": "Alert triggers when safety AI events get detected by the camera."
        }
      },
      "groups": {
        "driving-behavior": "Driving behavior",
        "engine": "Engine",
        "movement": "Movement",
        "security": "Security"
      }
    },
    "notifications": {
      "title": "Notifications",
      "types": {
        "email": "Email",
        "browser": "Browser and Mobile",
        "push-to-mobile": "Push to mobile",
        "push-mobile": "Push mobile"
      }
    },
    "vehicles": {
      "title": "Vehicles",
      "all": "All vehicles",
      "no-vehicles": "No vehicles",
      "several": "{val, plural, =1 {{val} vehicle} other {{val} vehicles}}",
      "severalMore": "{val, plural, =1 {{val} more vehicle} other {{val} more vehicles}}",
      "no-data": "Data was not loaded yet"
    },
    "recipients": {
      "title": "Recipients",
      "no-recipients": "No recipients",
      "several": "{val, plural, =1 {{val} recipient} other {{val} recipients}}",
      "no-data": "Data was not loaded yet"
    },
    "statuses": {
      "unreviewed": "Unreviewed",
      "unacknowledged": "Unacknowledged",
      "acknowledged": "Acknowledged",
      "reviewed": "Reviewed",
      "false-positive": "False positive"
    },
    "actions": {
      "title": "Actions (optional)",
      "request-video": {
        "label": "Request video",
        "tooltip": "Not all selected vehicles may support video. Video will be requested only for vehicles where it's supported.",
        "no-support-tooltip": "None of the vehicles selected are equipped with cameras.",
        "cameras": "Cameras",
        "duration": "Duration",
        "quality": {
          "label": "Video quality",
          "standard": "Standard",
          "high": "High"
        },
        "numberSeconds": "{val} seconds",
        "numberSecondsBeforeAfter": "{val} secs before and after",
        "cameraNumber": "Cam {val}",
        "customize": "Customize",
        "customize-defaults": "Customize defaults"
      },
      "commands": {
        "label": "Execute command",
        "tooltip": "Not all selected vehicles may support all the commands listed. Checked commands will execute only for vehicles where they are enabled.",
        "no-support-tooltip": "None of the vehicles selected have device commands available."
      }
    }
  },
  "fleet-settings": {
    "title": "Settings",
    "success-edit": {
      "detail": "Settings updated successfully."
    },
    "navigation": {
      "general": "General",
      "homebase": "Homebase",
      "vehicles": "Vehicles",
      "users": "Users"
    },
    "general": {
      "header": "General",
      "units-of-measure": "Units of measure",
      "geofence-names": "Display geofence names instead of address in Fleet, Alerts, Reports.",
      "measurement-labels": {
        "distance": "Distance",
        "volume": "Volume",
        "consumption": "Consumption",
        "temperature": "Temperature"
      }
    },
    "homebase": {
      "header": "Homebase",
      "address": "Address",
      "city": "City",
      "region": "State / Region",
      "zip-code": "Postal code / ZIP",
      "country": "Country"
    },
    "vehicle-edit": {
      "header": "Edit vehicle",
      "form": {
        "about-vehicle": "About vehicle",
        "name": "Name",
        "year": "Year",
        "make": "Make",
        "model": "Model",
        "license-plate": "License plate",
        "device-id": "Telematics device ID",
        "select-icon": "Select icon",
        "select-color": "Select color",
        "current-odometer": "Current odometer",
        "current-odometer-hint": "The odometer value is calculated based on GPS positioning. Update the current value as needed to match your vehicle's true odometer."
      }
    },
    "vehicles-list": {
      "header": "Vehicles",
      "grid": {
        "vehicle": "Vehicle",
        "device-id": "Telematics device ID",
        "last-active": "Last active",
        "no-data-title": "No vehicles added"
      }
    },
    "users": {
      "header": "Users",
      "button": {
        "add-user": "Add new user"
      }
    }
  },
  "sign-in": {
    "heading": "Sign in",
    "username": "Username",
    "password": "Password",
    "forgot_password": "Forgot password",
    "remember_me": "Remember me",
    "greeting": "Welcome back to",
    "error-message": "Invalid username or password"
  },
  "sw-update": {
    "new-version": "New version is available",
    "install-btn": "Reload the page"
  },
  "time-since": {
    "less-than-minute": "less than min ago",
    "minutes": "{val, plural, =1 {{val} min} other {{val} min}} ago",
    "hours": "{val, plural, =1 {{val} hour} other {{val} hours}} ago",
    "days": "{val, plural, =1 {{val} day} other {{val} days}} ago"
  },
  "ti-admin": {
    "menu": {
      "fleet-accounts": "Fleet accounts",
      "devices": "Devices",
      "billing": "Billing",
      "users": "Users",
      "white-label": "White label",
      "recycling-bin": "Recycling bin"
    },
    "devices": {
      "grid": {
        "device-name": "Device name",
        "brand-and-model": "Brand and model",
        "imei-serial": "IMEI / Serial",
        "phone-number-iccid": "Phone number / ICCID",
        "created": "Created",
        "fleet": "Fleet",
        "server": "Server",
        "last-active": "Last active",
        "device-test": "Device test",
        "edit-device": "Edit device",
        "copy-video-settings": "Copy video settings",
        "delete-device": "Delete device",
        "reload": "Reload",
        "test": "Test",
        "imei-serial-copied": "IMEI / Serial successfully copied!",
        "server-copied": "Server successfully copied!"
      },
      "total": "Total",
      "billable": "Billable",
      "add-new-device": "Add new device",
      "manage-commands": "Manage commands",
      "edit-device": "Edit device",
      "device-name": "Device name",
      "enter-name": "Enter name",
      "device-brand-and-model": "Device brand and model",
      "select-brand-and-model": "Select brand and model",
      "video-device": "Video device",
      "video-channels": "Video channels",
      "select-number-of-channels": "Select the number of channels",
      "imei-or-serial": "IMEI or Serial number",
      "enter-number": "Enter number",
      "sim-card-phone-iccid": "SIM card phone number or ICCID",
      "device-password": "Device password",
      "fleet-account": "Fleet account",
      "assign-to-fleet-account": "Assign to fleet account",
      "select-fleet": "Select fleet",
      "no-data": {
        "title": "No items to display",
        "description": "No devices have yet been added.",
        "error-title": "No data",
        "error-description": "There were problems retrieving the data. Try to reload."
      },
      "test": {
        "success": "Last device message received",
        "unsuccess": "No device messages found",
        "tryAgain": "Please check device configuration and confirm that the SIM is active, then try again."
      },
      "sim-check": "For SIM card phone number use + sign followed by 9 to 15 digits, e.g., +123456789; for ICCID 19 or 20 digit value is expected",
      "ident-check": {
        "^\\d{15}$": "15-digit numeric value is expected for the selected device model",
        "^(\\d{14})$": "14-digit numeric value is expected for the selected device model",
        "^\\d+$": "Numeric value is expected for the selected device model",
        "^[\\w\\-:!%$.*@]+([ ]+[\\w\\-:!%$.*@]+)*$": "Alphanumeric value is expected for the selected device model (special symbols are allowed)",
        "^(?:\\d{15}|\\d{1,10})$": "15-digit or 10-and-below-digit numeric value is expected for the selected device model",
        "^\\d{9,10}$": "9 or 10-digit numeric value is expected for the selected device model",
        "^(\\d{12})$": "12-digit numeric value is expected for the selected device model",
        "^[0-9A-Z]+": "Alphanumeric value is expected for the selected device model",
        "^\\d{10}": "10-digit numeric value is expected for the selected device model",
        "^\\w{10}": "10-digit alphanumeric value is expected for the selected device model"
      },
      "add": {
        "success": "New device was added!",
        "with-errors": "New device was added, but there were some errors in process!",
        "add-new": "Add new",
        "upload-list": "Upload list from CSV",
        "load-list": {
          "title": "Add new devices: Upload list from CSV",
          "file-requirements": "File requirements",
          "requirements": {
            "format": "Format: CSV",
            "size": "Max size: 1 MB",
            "rows": "Max number of rows: 1000",
            "columns": "Max number of columns: 10",
            "errors": {
              "wrong-format": "Wrong file format",
              "use-csv": "Please select CSV file",
              "wrong-size": "Wrong file size",
              "use-smaller-size": "Please select file with size not bigger than 1 MB",
              "error-parse": "Error to file parse",
              "try-other-file": "Please try to select other CSV file",
              "wrong-rows": "Wrong rows number",
              "use-rows": "Please select file with rows number from 1 to 1000",
              "wrong-columns": "Wrong columns number",
              "use-columns": "Please select file with columns number from 2 to 10",
              "not-meet-requirements": "File doesn't meet the requirements.",
              "title": "Error"
            }
          },
          "select-file": "Select file",
          "choose-brand-and-model": "Choose brand and model",
          "select-device-type": "Select device type",
          "abort-validation": {
            "summary": "Are you sure you want abort validating process?",
            "description": "This process will be aborted, you will lose the current progress."
          },
          "abort-adding": {
            "summary": "Are you sure you want abort adding process?",
            "description": "This process will be aborted, some devices won't be added."
          },
          "drag-and-drop": "Drag and drop CSV file to upload",
          "file-select-description": "Your file will be analyzed before being added.",
          "file-uploaded": "The file has been successfully uploaded.",
          "configuration-description": "To properly identify device information, please match the following fields",
          "first-row-is-title": "First row contains column titles that should be skipped",
          "password": "Password",
          "phone-number": "Phone number",
          "optional": "optional",
          "select-column": "Select column",
          "column": "Column",
          "validating-data": "Validating device data",
          "validate-errors": {
            "name-length": "Please use for name from 1 to 100 symbols",
            "no-ident": "No IMEI / Serial value",
            "dublicate-ident": "Device with such IMEI / Serial already exists in file",
            "wrong-ident": "Wrong IMEI / Serial",
            "wrong-phone": "Wrong phone"
          },
          "records-result": "{validatedNumber} records",
          "validated-description": "were validated successfully.",
          "add-button": "Add devices ({val})",
          "proceed-adding": "Please proceed with adding devices to your account.",
          "validation-result-part-1": "{unvalidatedNumber} records could not be validated and will be skipped. Their locations in",
          "validation-result-part-2": "file are",
          "row-number": "Row {number}",
          "adding-devices": "Adding devices to your account.",
          "take-time": "It may take some time",
          "completed": "Completed",
          "adding-completed": "The process of adding devices to your account was completed.",
          "added-success": "{addedNumber} devices were added",
          "added-warn": "{addedNumber} out of {allNumber} devices were added",
          "devices-not-added": "{number} devices were not added",
          "imei": "IMEI"
        }
      },
      "edit": {
        "success": "Changes to the device were saved!",
        "with-errors": "Changes to the device were saved, but there were some errors in process!",
        "dialog": {
          "unassign-question": "Are you sure you want to unassign device \"{deviceName}\" from the Fleet account?",
          "reassign-question": "Are you sure you want to unassign device from the Fleet account \"{fleetName}\" and assign it to Fleet account \"{newFleetName}\"?",
          "unassign-warn": "Unassigning device from the Fleet account will delete the corresponding vehicle and its history.",
          "reassign-warn": "This action will delete the corresponding vehicle  and its history from Fleet account \"{fleetName}\".",
          "unassign-button": "Yes, unassign",
          "cancel-button": "Cancel",
          "reduce-media-storage": {
            "summary": "Storage capacity reduction",
            "question": "Please note that reducing the storage capacity may lead to the unintended removal of media items."
          }
        }
      },
      "delete": {
        "success": "Device was deleted!",
        "with-errors": "Device was deleted, but there were some errors in process!",
        "fail": "Device wasn't deleted!",
        "dialog": {
          "question": "Are you sure you want to delete device \"{deviceName}\"?",
          "fleet-data-warn": "Historical device data will be deleted too.",
          "confirm-button": "Yes, delete",
          "cancel-button": "Cancel"
        }
      },
      "bulk-actions": {
        "device-selected": "{val, plural, =1 {{val} device} other {{val} devices}} selected",
        "fail-description": "Device: {name}",
        "delete": {
          "selected": "Delete selected ({val})",
          "summary": "Are you sure you want to delete {val, plural, =1 {{val} device} other {{val} devices}}?",
          "header": "Deleting {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Deleting devices from your account.",
          "complete-description": "The process of deleting devices from your account was completed.",
          "devices-not-deleted": "{val, plural, =1 {{val} device was} other {{val} devices were}} not deleted",
          "deleted-success": "{allNumber} devices were deleted",
          "deleted-warn": "{deletedNumber} out of {allNumber} devices were deleted"
        },
        "assign": {
          "selected": "Assign to fleet ({val})",
          "disabled-tooltip": "Devices already assigned to fleet(s) must be unassigned first.",
          "question": {
            "header": "Assign devices to fleet",
            "description": "To assign devices to a fleet, select the fleet from the list and confirm the assignment (Demo fleets are excluded).",
            "confirm-button": "Assign devices ({val})"
          },
          "header": "Assigning {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Assigning devices to fleet \"{fleetName}\".",
          "complete-description": "The process of assigning device to fleet \"{fleetName}\" was completed.",
          "devices-not-assigned": "{val, plural, =1 {{val} device was} other {{val} devices were}} not assigned",
          "assigned-success": "{allNumber} devices were assigned to {fleetName}",
          "assigned-warn": "{assignedNumber} out of {allNumber} were assigned to {fleetName}"
        },
        "unassign": {
          "selected": "Unassign from fleet ({val})",
          "disabled-tooltip": "Only currently assigned to fleet(s) devices can be unassigned.",
          "question-summary": "Are you sure you want to unassign {val} device(s) from fleet accounts?",
          "question-description": "Unassigning devices from the fleet account will delete the corresponding vehicles and their history.",
          "confirm-button": "Unassign devices ({val})",
          "header": "Unassigning {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Unassigning devices from fleet(s).",
          "complete-description": "The process of unassigning devices from fleet(s) was completed.",
          "devices-not-unassigned": "{val, plural, =1 {{val} device was} other {{val} devices were}} not unassigned",
          "unassigned-success": "{allNumber} devices were unassigned from fleet(s)",
          "unassigned-warn": "{assignedNumber} out of {allNumber} were unassigned from fleet(s)"
        }
      },
      "tabs": {
        "configuration": "Configuration",
        "sensors": "Engine data / sensors",
        "commands": "Commands",
        "video": "Video",
        "ignition": "Ignition",
        "most-recent-value": "Most recent value",
        "never-received": "never received",
        "vehicle-battery": "Vehicle battery",
        "odometer": "Odometer",
        "gps-calculated": "GPS-calculated",
        "parameter-based": "Parameter-based",
        "error-get-commands-list": "Unable to load command list.",
        "no-commands": {
          "summary": "No commands added",
          "description": "No commands added for the selected device brand and model."
        }
      },
      "media-config": {
        "cameras": {
          "number-label": "How many cameras are enabled / connected to the device?",
          "no-cameras": "No cameras",
          "set-label": "Set names for the cameras",
          "camera-number": "Camera {number}",
          "camera-name": "Camera name"
        },
        "video-quality": {
          "label": "Default video quality",
          "standard": "Standard",
          "high": "High",
          "enable-select": "Enable video quality selection for fleet users",
          "not-available-tooltip": "The camera is capable of recording in only one quality."
        },
        "max-media-storage": {
          "label": "Maximum media storage",
          "hint": "Storage for photos and videos retrieved from the device.",
          "description": "1 GB of media storage is included at no additional cost. Additional storage is priced at $0.50 per GB. Charges are based on actual storage usage, up to the maximum limit you select. Once the maximum limit is reached, older media is automatically overwritten with new media."
        },
        "max-clip-duration": {
          "label": "Maximum clip duration (minutes)",
          "hint": "Maximum duration of video clips retrievable by fleet users."
        },
        "max-live-streaming": {
          "label": "Maximum video streaming (minutes)",
          "no-limit": "Do not limit",
          "unlimited": "Unlimited",
          "description": "Set a monthly limit on the total number of minutes a fleet user can stream video. When streaming from multiple cameras simultaneously, the minutes used are multiplied by the number of cameras.",
          "enter-limit": "Enter limit"
        },
        "video-stream-timeout": {
          "label": "Live stream and playback timeout (seconds)",
          "description": "Time period before a pop-up appears asking the user to confirm they are still watching. If no response is received within 30 seconds of the pop-up appearing, the live stream or playback will terminate.",
          "enter-timeout": "Enter timeout"
        },
        "audio": "Audio",
        "copy": {
          "label": "Copy current video settings to additional devices",
          "select-devices": "Select devices"
        },
        "max-playback-time": {
          "label": "Maximum playback time (minutes)",
          "no-limit": "Do not limit",
          "unlimited": "Unlimited",
          "description": "Set a monthly limit on total number of minutes a fleet user can playback video from cameras. When playing back from multiple cameras simultaneously, the minutes are multiplied by the number of cameras.",
          "enter-limit": "Enter limit",
          "not-available-tooltip": "Playback duration cannot be determined for the camera."
        }
      },
      "commands": {
        "title": "Manage commands",
        "grid": {
          "name": "Command name",
          "model": "Device brand and model",
          "config": "Command fields",
          "number-of-devices": "Number of devices"
        },
        "add-command": "Add command",
        "edit-command": "Edit command",
        "delete-command": "Delete command",
        "assign-devices": "Assign command to device(s)",
        "unassign-devices": "Unassign command from device(s)",
        "select": "Select",
        "no-data": {
          "title": "No items to display",
          "description": "No commands have yet been added."
        },
        "no-devices": "No devices",
        "several-devices": "{val, plural, =1 {{val} device} other {{val} devices}}",
        "several-devices-more": "{val, plural, =1 {{val} more device} other {{val} more devices}}",
        "delete-dialog": {
          "summary": "Are you sure you want to delete command \"{name}\"?",
          "description": "Deleting command will get it unassigned from all devices it is currently assigned to."
        },
        "edit-dialog": {
          "summary": "Are you sure you want to save changes to \"{name}\"?",
          "description": "Command will get updated for all devices it is currently assigned to.",
          "save": "Yes, save"
        },
        "deleted": "Command deleted successfully",
        "added": "Command added successfully",
        "edited": "Command updated successfully",
        "fields": {
          "command-id": "Command ID",
          "hex": "HEX payload",
          "payload": "Payload",
          "message": "Message",
          "command-type": "Command type",
          "parameters": "Parameters",
          "text": "Payload",
          "command": "Command value / payload",
          "wait-ack": "Wait ACK",
          "command-name": "Command name",
          "instruction-type": "Instruction type",
          "crlf": "CR / LF",
          "message-type": "Message type",
          "append-id-ts": "Command ID",
          "cmd": "Command",
          "password": "Password",
          "argument": "Argument",
          "code": "HEX code",
          "wait-response": "Wait response",
          "report-body": "Report body",
          "report-id": "Report ID",
          "cmd-body": "Async message body",
          "cmd-type": "Async message type"
        },
        "not-available": "Not available",
        "not-available-description": "Commands are not available for Device brand and model selected.",
        "enter-string": "Enter string",
        "enter-number": "Enter number",
        "enter-json": "Enter JSON object",
        "enter-command-name": "Enter command name",
        "true": "True",
        "false": "False",
        "bulk": {
          "no-devices": "No devices",
          "assign": {
            "header": "Assign command to devices",
            "description": {
              "command": "Command that will be assigned",
              "grid": "Select devices from the list to assign the command to"
            },
            "no-items": "There are no devices to assign the command to.",
            "confirm-button": "Assign command",
            "success-message": "Command ''{commandName}'' was assigned to {val, plural, =1 {{val} device} other {{val} devices}}",
            "warn-message": "Command ''{commandName}'' was assigned to {val} out of {allVal} devices"
          },
          "unassign": {
            "header": "Unassign command from devices",
            "description": {
              "command": "Command that will be unassigned",
              "grid": "Select devices from the list to unassign the command from"
            },
            "no-items": "There are no devices to unassign the command from.",
            "confirm-button": "Unassign command",
            "success-message": "Command ''{commandName}'' was unassigned from {val, plural, =1 {{val} device} other {{val} devices}}",
            "warn-message": "Command ''{commandName}'' was unassigned from {val} out of {allVal} devices"
          },
          "search-devices": "Search devices"
        }
      },
      "media-usage": {
        "title": "Video usage",
        "description": "Please note that traffic figures should not be used as a reliable measure of cellular traffic consumption.",
        "no-video-devices": "No video devices have yet been added.",
        "grid": {
          "cameras": "Cameras",
          "media-storage": "Media storage",
          "media-traffic": "Media traffic",
          "live-stream-duration": "Live stream duration",
          "live-stream-traffic": "Live stream traffic",
          "playback-duration": "Playback duration",
          "playback-traffic": "Playback traffic"
        },
        "export": {
          "media-storage": "Media storage, GB",
          "media-traffic": "Media traffic, GB",
          "live-stream-duration": "Live stream duration, min",
          "live-stream-traffic": "Live stream traffic, GB",
          "playback-duration": "Playback duration, min",
          "playback-traffic": "Playback traffic, GB"
        },
        "no-video-usage": "No video usage to show",
        "unlimited": "Unlimited"
      },
      "copy-media-config": {
        "title": "Copy video settings to other devices",
        "confirm-button": "Copy settings",
        "applied-settings-label": "Setting that will be applied",
        "select-devices-label": "Select the devices to copy video settings to (only video devices are shown)",
        "search-video-devices": "Search video devices",
        "no-devices": {
          "title": "No devices",
          "description": "There are no devices to copy video settings to."
        },
        "success-message": "Video settings were copied to {val, plural, =1 {{val} device} other {{val} devices}}",
        "warn-message": "Video settings were copied {val} out of {allVal} devices",
        "cameras-enabled": "Cameras enabled",
        "no-cameras-enabled": "No cameras enabled",
        "cameras-number": "{val, plural, =1 {{val} camera} other {{val} cameras}}",
        "video-quality-selection": {
          "enabled": "video quality is enabled for selection by fleet users",
          "disabled": "video quality is disabled for selection by fleet users"
        }
      }
    },
    "billing": {
      "dashboard": "Billing dashboard",
      "history": "Billing history",
      "month-to-date-amount": "Month-to-date usage",
      "view-details": "View details",
      "invoice-history": "Billing history",
      "grid": {
        "invoice": "Invoice",
        "amount": "Amount",
        "billingPeriod": "Billing period",
        "status": "Status",
        "no-data-title": "No invoices to display",
        "no-data-description": "The first invoice is yet to be issued"
      }
    },
    "recycling-bin": {
      "grid": {
        "no-data-title": "No items to display",
        "no-data-description": "Recycling bin is not active."
      }
    },
    "white-label": {
      "company-logo": "Company logo",
      "full-logo": "Full logo",
      "image-cropper": {
        "crop-image": "Crop an image",
        "image-resize": "You can resize and move the selection contours.",
        "aspect-ratio": "The aspect ratio is fixed and cannot be changed."
      },
      "updated-message": {
        "summary": "Success",
        "detail": "Changes were saved!"
      },
      "compact-logo": "Compact logo",
      "drag-and-drop": "or drag and drop an image here",
      "custom-domain": "Custom domain",
      "enter-domain": "Enter domain",
      "enter-key": "Enter key",
      "domain-instructions": "Domain setup instructions",
      "dns-label": "Add the following DNS records for your domain:",
      "dns-hint": "This has to be done with the provider from whom you purchased your domain name, such as GoDaddy, Namecheap, Google Domains, etc.",
      "type": "Type",
      "hostname": "Hostname",
      "data-url": "Data/URL",
      "google-maps-api-key": "Google Maps API key",
      "domain-instructions-not-ready": "Your custom domain is currently being processed."
    },
    "fleets": {
      "title": "Fleet accounts",
      "tabs": {
        "devices": "Devices / vehicles",
        "users": "Users",
        "billing": "Billing"
      },
      "fields": {
        "name": "Account name ",
        "devices-count": "Vehicles/devices",
        "created-at": "Created",
        "contact-name": "Contact name",
        "email": "Email",
        "phone": "Phone number",
        "invoices-overdue": "Invoice(s) overdue",
        "measurement-distance": "Distance",
        "measurement-volume": "Volume",
        "measurement-temperature": "Temperature",
        "measurement-consumption": "Consumption",
        "suspended": "Suspended",
        "demo": "Demo"
      },
      "billing": {
        "not-enabled": "Fleet billing is not enabled",
        "not-enabled-description": "Please reach out to your Telematics inSights contact to discuss."
      },
      "grid": {
        "launch-new-btn": "Launch new fleet",
        "edit-fleet": "Edit fleet",
        "suspended": "Suspended",
        "demo": "Demo",
        "no-data-title": "No fleets created"
      },
      "page": {
        "about": "About fleet",
        "lifetime-value": "Lifetime value",
        "devices": "Devices",
        "address": "Address",
        "activate-btn": "Resume",
        "suspend-btn": "Suspend",
        "edit-btn": "Edit",
        "delete-btn": "Delete",
        "invited-msg": "Invited"
      },
      "form": {
        "input-label": {
          "company-name": "Company name",
          "contact-name": "Contact name",
          "email": "Email",
          "phone-number": "Phone number",
          "country": "Country",
          "time-zone": "Time zone",
          "address": "Address",
          "units-of-measure": "Units of measure",
          "miles": "Miles",
          "gallons": "Gallons",
          "pricing-plan": "Pricing plan"
        },
        "input-placeholder": {
          "enter-name": "Enter name",
          "enter-number": "Enter number",
          "street-address": "Street address",
          "city": "City",
          "state-region": "State / Region",
          "postal-code-zip": "Postal Code / ZIP",
          "distance": "Distance",
          "volume": "Volume",
          "consumption": "Consumption",
          "temperature": "Temperature",
          "demo": "Demo"
        }
      },
      "delete-dialog": {
        "summary": "Are you sure you want to delete fleet \"{name}\"?",
        "description": "This will delete the Fleet account and all of its content. This is action is irreversible. Please confirm that you want to proceed.",
        "delete-message-hint": "Enter \"{ message }\" to confirm.",
        "delete-confirm-text": "DELETE",
        "confirm-button": "Yes, delete",
        "cancel-button": "Cancel"
      },
      "suspend-dialog": {
        "summary": "Are you sure you want to suspend fleet \"{name}\"?",
        "description": "You are about to suspend this Fleet account. Its users will not be able to log in for as long as the account is suspended.",
        "confirm-button": "Yes, suspend",
        "cancel-button": "Cancel"
      },
      "activate-dialog": {
        "summary": "Are you sure you want to resume fleet \"{name}\"?",
        "description": "You are about to resume this Fleet account. It will reactivate billing cycle.",
        "confirm-button": "Yes, resume",
        "cancel-button": "Cancel"
      },
      "notifications": {
        "launched-title": "New Fleet account was launched!",
        "launched-summary": "Invitation email was sent to \"{email}\"",
        "updated": "Fleet \"{name}\" successfully updated.",
        "suspended": "Fleet \"{name}\" successfully suspended.",
        "resumed": "Fleet \"{name}\" successfully resumed.",
        "deleted": "Fleet \"{name}\" successfully deleted."
      },
      "errors": {
        "demo-limits-reached": "You reached the maximum number of Demo Fleet accounts.",
        "unknown": "Something went wrong. Please try again later or contact support."
      },
      "pricing-plans": {
        "0": "Track & Trace",
        "1": "Standard inSights",
        "2": "Advanced inSights",
        "3": "Premium inSights"
      }
    }
  },
  "tsp-admin": {
    "menu": {
      "fleet-accounts": "Fleet accounts",
      "devices": "Devices",
      "billing": "Billing",
      "users": "Users",
      "white-label": "White label",
      "recycling-bin": "Recycling bin"
    },
    "devices": {
      "grid": {
        "device-name": "Device name",
        "brand-and-model": "Brand and model",
        "imei-serial": "IMEI / Serial",
        "phone-number-iccid": "Phone number / ICCID",
        "created": "Created",
        "fleet": "Fleet",
        "server": "Server",
        "last-active": "Last active",
        "device-test": "Device test",
        "edit-device": "Edit device",
        "copy-video-settings": "Copy video settings",
        "delete-device": "Delete device",
        "reload": "Reload",
        "test": "Test",
        "imei-serial-copied": "IMEI / Serial successfully copied!",
        "server-copied": "Server successfully copied!"
      },
      "total": "Total",
      "billable": "Billable",
      "add-new-device": "Add new device",
      "manage-commands": "Manage commands",
      "edit-device": "Edit device",
      "device-name": "Device name",
      "enter-name": "Enter name",
      "device-brand-and-model": "Device brand and model",
      "select-brand-and-model": "Select brand and model",
      "video-device": "Video device",
      "video-channels": "Video channels",
      "select-number-of-channels": "Select the number of channels",
      "imei-or-serial": "IMEI or Serial number",
      "enter-number": "Enter number",
      "sim-card-phone-iccid": "SIM card phone number or ICCID",
      "device-password": "Device password",
      "fleet-account": "Fleet account",
      "assign-to-fleet-account": "Assign to fleet account",
      "select-fleet": "Select fleet",
      "no-data": {
        "title": "No items to display",
        "description": "No devices have yet been added.",
        "error-title": "No data",
        "error-description": "There were problems retrieving the data. Try to reload."
      },
      "test": {
        "success": "Last device message received",
        "unsuccess": "No device messages found",
        "tryAgain": "Please check device configuration and confirm that the SIM is active, then try again."
      },
      "sim-check": "For SIM card phone number use + sign followed by 9 to 15 digits, e.g., +123456789; for ICCID 19 or 20 digit value is expected",
      "ident-check": {
        "^\\d{15}$": "15-digit numeric value is expected for the selected device model",
        "^(\\d{14})$": "14-digit numeric value is expected for the selected device model",
        "^\\d+$": "Numeric value is expected for the selected device model",
        "^[\\w\\-:!%$.*@]+([ ]+[\\w\\-:!%$.*@]+)*$": "Alphanumeric value is expected for the selected device model (special symbols are allowed)",
        "^(?:\\d{15}|\\d{1,10})$": "15-digit or 10-and-below-digit numeric value is expected for the selected device model",
        "^\\d{9,10}$": "9 or 10-digit numeric value is expected for the selected device model",
        "^(\\d{12})$": "12-digit numeric value is expected for the selected device model",
        "^[0-9A-Z]+": "Alphanumeric value is expected for the selected device model",
        "^\\d{10}": "10-digit numeric value is expected for the selected device model",
        "^\\w{10}": "10-digit alphanumeric value is expected for the selected device model"
      },
      "add": {
        "success": "New device was added!",
        "with-errors": "New device was added, but there were some errors in process!",
        "add-new": "Add new",
        "upload-list": "Upload list from CSV",
        "load-list": {
          "title": "Add new devices: Upload list from CSV",
          "file-requirements": "File requirements",
          "requirements": {
            "format": "Format: CSV",
            "size": "Max size: 1 MB",
            "rows": "Max number of rows: 1000",
            "columns": "Max number of columns: 10",
            "errors": {
              "wrong-format": "Wrong file format",
              "use-csv": "Please select CSV file",
              "wrong-size": "Wrong file size",
              "use-smaller-size": "Please select file with size not bigger than 1 MB",
              "error-parse": "Error to file parse",
              "try-other-file": "Please try to select other CSV file",
              "wrong-rows": "Wrong rows number",
              "use-rows": "Please select file with rows number from 1 to 1000",
              "wrong-columns": "Wrong columns number",
              "use-columns": "Please select file with columns number from 2 to 10",
              "not-meet-requirements": "File doesn't meet the requirements.",
              "title": "Error"
            }
          },
          "select-file": "Select file",
          "choose-brand-and-model": "Choose brand and model",
          "select-device-type": "Select device type",
          "abort-validation": {
            "summary": "Are you sure you want abort validating process?",
            "description": "This process will be aborted, you will lose the current progress."
          },
          "abort-adding": {
            "summary": "Are you sure you want abort adding process?",
            "description": "This process will be aborted, some devices won't be added."
          },
          "drag-and-drop": "Drag and drop CSV file to upload",
          "file-select-description": "Your file will be analyzed before being added.",
          "file-uploaded": "The file has been successfully uploaded.",
          "configuration-description": "To properly identify device information, please match the following fields",
          "first-row-is-title": "First row contains column titles that should be skipped",
          "password": "Password",
          "phone-number": "Phone number",
          "optional": "optional",
          "select-column": "Select column",
          "column": "Column",
          "validating-data": "Validating device data",
          "validate-errors": {
            "name-length": "Please use for name from 1 to 100 symbols",
            "no-ident": "No IMEI / Serial value",
            "dublicate-ident": "Device with such IMEI / Serial already exists in file",
            "wrong-ident": "Wrong IMEI / Serial",
            "wrong-phone": "Wrong phone"
          },
          "records-result": "{validatedNumber} records",
          "validated-description": "were validated successfully.",
          "add-button": "Add devices ({val})",
          "proceed-adding": "Please proceed with adding devices to your account.",
          "validation-result-part-1": "{unvalidatedNumber} records could not be validated and will be skipped. Their locations in",
          "validation-result-part-2": "file are",
          "row-number": "Row {number}",
          "adding-devices": "Adding devices to your account.",
          "take-time": "It may take some time",
          "completed": "Completed",
          "adding-completed": "The process of adding devices to your account was completed.",
          "added-success": "{addedNumber} devices were added",
          "added-warn": "{addedNumber} out of {allNumber} devices were added",
          "devices-not-added": "{number} devices were not added",
          "imei": "IMEI"
        }
      },
      "edit": {
        "success": "Changes to the device were saved!",
        "with-errors": "Changes to the device were saved, but there were some errors in process!",
        "dialog": {
          "unassign-question": "Are you sure you want to unassign device \"{deviceName}\" from the Fleet account?",
          "reassign-question": "Are you sure you want to unassign device from the Fleet account \"{fleetName}\" and assign it to Fleet account \"{newFleetName}\"?",
          "unassign-warn": "Unassigning device from the Fleet account will delete the corresponding vehicle and its history.",
          "reassign-warn": "This action will delete the corresponding vehicle  and its history from Fleet account \"{fleetName}\".",
          "unassign-button": "Yes, unassign",
          "cancel-button": "Cancel",
          "reduce-media-storage": {
            "summary": "Storage capacity reduction",
            "question": "Please note that reducing the storage capacity may lead to the unintended removal of media items."
          }
        }
      },
      "delete": {
        "success": "Device was deleted!",
        "with-errors": "Device was deleted, but there were some errors in process!",
        "fail": "Device wasn't deleted!",
        "dialog": {
          "question": "Are you sure you want to delete device \"{deviceName}\"?",
          "fleet-data-warn": "Historical device data will be deleted too.",
          "confirm-button": "Yes, delete",
          "cancel-button": "Cancel"
        }
      },
      "bulk-actions": {
        "device-selected": "{val, plural, =1 {{val} device} other {{val} devices}} selected",
        "fail-description": "Device: {name}",
        "delete": {
          "selected": "Delete selected ({val})",
          "summary": "Are you sure you want to delete {val, plural, =1 {{val} device} other {{val} devices}}?",
          "header": "Deleting {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Deleting devices from your account.",
          "complete-description": "The process of deleting devices from your account was completed.",
          "devices-not-deleted": "{val, plural, =1 {{val} device was} other {{val} devices were}} not deleted",
          "deleted-success": "{allNumber} devices were deleted",
          "deleted-warn": "{deletedNumber} out of {allNumber} devices were deleted"
        },
        "assign": {
          "selected": "Assign to fleet ({val})",
          "disabled-tooltip": "Devices already assigned to fleet(s) must be unassigned first.",
          "question": {
            "header": "Assign devices to fleet",
            "description": "To assign devices to a fleet, select the fleet from the list and confirm the assignment (Demo fleets are excluded).",
            "confirm-button": "Assign devices ({val})"
          },
          "header": "Assigning {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Assigning devices to fleet \"{fleetName}\".",
          "complete-description": "The process of assigning device to fleet \"{fleetName}\" was completed.",
          "devices-not-assigned": "{val, plural, =1 {{val} device was} other {{val} devices were}} not assigned",
          "assigned-success": "{allNumber} devices were assigned to {fleetName}",
          "assigned-warn": "{assignedNumber} out of {allNumber} were assigned to {fleetName}"
        },
        "unassign": {
          "selected": "Unassign from fleet ({val})",
          "disabled-tooltip": "Only currently assigned to fleet(s) devices can be unassigned.",
          "question-summary": "Are you sure you want to unassign {val} device(s) from fleet accounts?",
          "question-description": "Unassigning devices from the fleet account will delete the corresponding vehicles and their history.",
          "confirm-button": "Unassign devices ({val})",
          "header": "Unassigning {val, plural, =1 {{val} device} other {{val} devices}}",
          "description": "Unassigning devices from fleet(s).",
          "complete-description": "The process of unassigning devices from fleet(s) was completed.",
          "devices-not-unassigned": "{val, plural, =1 {{val} device was} other {{val} devices were}} not unassigned",
          "unassigned-success": "{allNumber} devices were unassigned from fleet(s)",
          "unassigned-warn": "{assignedNumber} out of {allNumber} were unassigned from fleet(s)"
        }
      },
      "tabs": {
        "configuration": "Configuration",
        "sensors": "Engine data / sensors",
        "commands": "Commands",
        "video": "Video",
        "ignition": "Ignition",
        "most-recent-value": "Most recent value",
        "never-received": "never received",
        "vehicle-battery": "Vehicle battery",
        "odometer": "Odometer",
        "gps-calculated": "GPS-calculated",
        "parameter-based": "Parameter-based",
        "error-get-commands-list": "Unable to load command list.",
        "no-commands": {
          "summary": "No commands added",
          "description": "No commands added for the selected device brand and model."
        }
      },
      "media-config": {
        "cameras": {
          "number-label": "How many cameras are enabled / connected to the device?",
          "no-cameras": "No cameras",
          "set-label": "Set names for the cameras",
          "camera-number": "Camera {number}",
          "camera-name": "Camera name"
        },
        "video-quality": {
          "label": "Default video quality",
          "standard": "Standard",
          "high": "High",
          "enable-select": "Enable video quality selection for fleet users",
          "not-available-tooltip": "The camera is capable of recording in only one quality."
        },
        "max-media-storage": {
          "label": "Maximum media storage",
          "hint": "Storage for photos and videos retrieved from the device.",
          "description": "1 GB of media storage is included at no additional cost. Additional storage is priced at $0.50 per GB. Charges are based on actual storage usage, up to the maximum limit you select. Once the maximum limit is reached, older media is automatically overwritten with new media."
        },
        "max-clip-duration": {
          "label": "Maximum clip duration (minutes)",
          "hint": "Maximum duration of video clips retrievable by fleet users."
        },
        "max-live-streaming": {
          "label": "Maximum video streaming (minutes)",
          "no-limit": "Do not limit",
          "unlimited": "Unlimited",
          "description": "Set a monthly limit on the total number of minutes a fleet user can stream video. When streaming from multiple cameras simultaneously, the minutes used are multiplied by the number of cameras.",
          "enter-limit": "Enter limit"
        },
        "video-stream-timeout": {
          "label": "Live stream and playback timeout (seconds)",
          "description": "Time period before a pop-up appears asking the user to confirm they are still watching. If no response is received within 30 seconds of the pop-up appearing, the live stream or playback will terminate.",
          "enter-timeout": "Enter timeout"
        },
        "audio": "Audio",
        "copy": {
          "label": "Copy current video settings to additional devices",
          "select-devices": "Select devices"
        },
        "max-playback-time": {
          "label": "Maximum playback time (minutes)",
          "no-limit": "Do not limit",
          "unlimited": "Unlimited",
          "description": "Set a monthly limit on total number of minutes a fleet user can playback video from cameras. When playing back from multiple cameras simultaneously, the minutes are multiplied by the number of cameras.",
          "enter-limit": "Enter limit",
          "not-available-tooltip": "Playback duration cannot be determined for the camera."
        }
      },
      "commands": {
        "title": "Manage commands",
        "grid": {
          "name": "Command name",
          "model": "Device brand and model",
          "config": "Command fields",
          "number-of-devices": "Number of devices"
        },
        "add-command": "Add command",
        "edit-command": "Edit command",
        "delete-command": "Delete command",
        "assign-devices": "Assign command to device(s)",
        "unassign-devices": "Unassign command from device(s)",
        "select": "Select",
        "no-data": {
          "title": "No items to display",
          "description": "No commands have yet been added."
        },
        "no-devices": "No devices",
        "several-devices": "{val, plural, =1 {{val} device} other {{val} devices}}",
        "several-devices-more": "{val, plural, =1 {{val} more device} other {{val} more devices}}",
        "delete-dialog": {
          "summary": "Are you sure you want to delete command \"{name}\"?",
          "description": "Deleting command will get it unassigned from all devices it is currently assigned to."
        },
        "edit-dialog": {
          "summary": "Are you sure you want to save changes to \"{name}\"?",
          "description": "Command will get updated for all devices it is currently assigned to.",
          "save": "Yes, save"
        },
        "deleted": "Command deleted successfully",
        "added": "Command added successfully",
        "edited": "Command updated successfully",
        "fields": {
          "command-id": "Command ID",
          "hex": "HEX payload",
          "payload": "Payload",
          "message": "Message",
          "command-type": "Command type",
          "parameters": "Parameters",
          "text": "Payload",
          "command": "Command value / payload",
          "wait-ack": "Wait ACK",
          "command-name": "Command name",
          "instruction-type": "Instruction type",
          "crlf": "CR / LF",
          "message-type": "Message type",
          "append-id-ts": "Command ID",
          "cmd": "Command",
          "password": "Password",
          "argument": "Argument",
          "code": "HEX code",
          "wait-response": "Wait response",
          "report-body": "Report body",
          "report-id": "Report ID",
          "cmd-body": "Async message body",
          "cmd-type": "Async message type"
        },
        "not-available": "Not available",
        "not-available-description": "Commands are not available for Device brand and model selected.",
        "enter-string": "Enter string",
        "enter-number": "Enter number",
        "enter-json": "Enter JSON object",
        "enter-command-name": "Enter command name",
        "true": "True",
        "false": "False",
        "bulk": {
          "no-devices": "No devices",
          "assign": {
            "header": "Assign command to devices",
            "description": {
              "command": "Command that will be assigned",
              "grid": "Select devices from the list to assign the command to"
            },
            "no-items": "There are no devices to assign the command to.",
            "confirm-button": "Assign command",
            "success-message": "Command ''{commandName}'' was assigned to {val, plural, =1 {{val} device} other {{val} devices}}",
            "warn-message": "Command ''{commandName}'' was assigned to {val} out of {allVal} devices"
          },
          "unassign": {
            "header": "Unassign command from devices",
            "description": {
              "command": "Command that will be unassigned",
              "grid": "Select devices from the list to unassign the command from"
            },
            "no-items": "There are no devices to unassign the command from.",
            "confirm-button": "Unassign command",
            "success-message": "Command ''{commandName}'' was unassigned from {val, plural, =1 {{val} device} other {{val} devices}}",
            "warn-message": "Command ''{commandName}'' was unassigned from {val} out of {allVal} devices"
          },
          "search-devices": "Search devices"
        }
      },
      "media-usage": {
        "title": "Video usage",
        "description": "Please note that traffic figures should not be used as a reliable measure of cellular traffic consumption.",
        "no-video-devices": "No video devices have yet been added.",
        "grid": {
          "cameras": "Cameras",
          "media-storage": "Media storage",
          "media-traffic": "Media traffic",
          "live-stream-duration": "Live stream duration",
          "live-stream-traffic": "Live stream traffic",
          "playback-duration": "Playback duration",
          "playback-traffic": "Playback traffic"
        },
        "export": {
          "media-storage": "Media storage, GB",
          "media-traffic": "Media traffic, GB",
          "live-stream-duration": "Live stream duration, min",
          "live-stream-traffic": "Live stream traffic, GB",
          "playback-duration": "Playback duration, min",
          "playback-traffic": "Playback traffic, GB"
        },
        "no-video-usage": "No video usage to show",
        "unlimited": "Unlimited"
      },
      "copy-media-config": {
        "title": "Copy video settings to other devices",
        "confirm-button": "Copy settings",
        "applied-settings-label": "Setting that will be applied",
        "select-devices-label": "Select the devices to copy video settings to (only video devices are shown)",
        "search-video-devices": "Search video devices",
        "no-devices": {
          "title": "No devices",
          "description": "There are no devices to copy video settings to."
        },
        "success-message": "Video settings were copied to {val, plural, =1 {{val} device} other {{val} devices}}",
        "warn-message": "Video settings were copied {val} out of {allVal} devices",
        "cameras-enabled": "Cameras enabled",
        "no-cameras-enabled": "No cameras enabled",
        "cameras-number": "{val, plural, =1 {{val} camera} other {{val} cameras}}",
        "video-quality-selection": {
          "enabled": "video quality is enabled for selection by fleet users",
          "disabled": "video quality is disabled for selection by fleet users"
        }
      }
    },
    "billing": {
      "dashboard": "Billing dashboard",
      "history": "Billing history",
      "month-to-date-amount": "Month-to-date usage",
      "view-details": "View details",
      "invoice-history": "Billing history",
      "grid": {
        "invoice": "Invoice",
        "amount": "Amount",
        "billingPeriod": "Billing period",
        "status": "Status",
        "no-data-title": "No invoices to display",
        "no-data-description": "The first invoice is yet to be issued"
      }
    },
    "recycling-bin": {
      "grid": {
        "no-data-title": "No items to display",
        "no-data-description": "Recycling bin is not active."
      }
    },
    "white-label": {
      "company-logo": "Company logo",
      "full-logo": "Full logo",
      "image-cropper": {
        "crop-image": "Crop an image",
        "image-resize": "You can resize and move the selection contours.",
        "aspect-ratio": "The aspect ratio is fixed and cannot be changed."
      },
      "updated-message": {
        "summary": "Success",
        "detail": "Changes were saved!"
      },
      "compact-logo": "Compact logo",
      "drag-and-drop": "or drag and drop an image here",
      "custom-domain": "Custom domain",
      "enter-domain": "Enter domain",
      "enter-key": "Enter key",
      "domain-instructions": "Domain setup instructions",
      "dns-label": "Add the following DNS records for your domain:",
      "dns-hint": "This has to be done with the provider from whom you purchased your domain name, such as GoDaddy, Namecheap, Google Domains, etc.",
      "type": "Type",
      "hostname": "Hostname",
      "data-url": "Data/URL",
      "google-maps-api-key": "Google Maps API key",
      "domain-instructions-not-ready": "Your custom domain is currently being processed."
    },
    "fleets": {
      "title": "Fleet accounts",
      "tabs": {
        "devices": "Devices / vehicles",
        "users": "Users",
        "billing": "Billing"
      },
      "fields": {
        "name": "Account name ",
        "devices-count": "Vehicles/devices",
        "created-at": "Created",
        "contact-name": "Contact name",
        "email": "Email",
        "phone": "Phone number",
        "invoices-overdue": "Invoice(s) overdue",
        "measurement-distance": "Distance",
        "measurement-volume": "Volume",
        "measurement-temperature": "Temperature",
        "measurement-consumption": "Consumption",
        "suspended": "Suspended",
        "demo": "Demo"
      },
      "billing": {
        "not-enabled": "Fleet billing is not enabled",
        "not-enabled-description": "Please reach out to your Telematics inSights contact to discuss."
      },
      "grid": {
        "launch-new-btn": "Launch new fleet",
        "edit-fleet": "Edit fleet",
        "suspended": "Suspended",
        "demo": "Demo",
        "no-data-title": "No fleets created"
      },
      "page": {
        "about": "About fleet",
        "lifetime-value": "Lifetime value",
        "devices": "Devices",
        "address": "Address",
        "activate-btn": "Resume",
        "suspend-btn": "Suspend",
        "edit-btn": "Edit",
        "delete-btn": "Delete",
        "invited-msg": "Invited"
      },
      "form": {
        "input-label": {
          "company-name": "Company name",
          "contact-name": "Contact name",
          "email": "Email",
          "phone-number": "Phone number",
          "country": "Country",
          "time-zone": "Time zone",
          "address": "Address",
          "units-of-measure": "Units of measure",
          "miles": "Miles",
          "gallons": "Gallons",
          "pricing-plan": "Pricing plan"
        },
        "input-placeholder": {
          "enter-name": "Enter name",
          "enter-number": "Enter number",
          "street-address": "Street address",
          "city": "City",
          "state-region": "State / Region",
          "postal-code-zip": "Postal Code / ZIP",
          "distance": "Distance",
          "volume": "Volume",
          "consumption": "Consumption",
          "temperature": "Temperature",
          "demo": "Demo"
        }
      },
      "delete-dialog": {
        "summary": "Are you sure you want to delete fleet \"{name}\"?",
        "description": "This will delete the Fleet account and all of its content. This is action is irreversible. Please confirm that you want to proceed.",
        "delete-message-hint": "Enter \"{ message }\" to confirm.",
        "delete-confirm-text": "DELETE",
        "confirm-button": "Yes, delete",
        "cancel-button": "Cancel"
      },
      "suspend-dialog": {
        "summary": "Are you sure you want to suspend fleet \"{name}\"?",
        "description": "You are about to suspend this Fleet account. Its users will not be able to log in for as long as the account is suspended.",
        "confirm-button": "Yes, suspend",
        "cancel-button": "Cancel"
      },
      "activate-dialog": {
        "summary": "Are you sure you want to resume fleet \"{name}\"?",
        "description": "You are about to resume this Fleet account. It will reactivate billing cycle.",
        "confirm-button": "Yes, resume",
        "cancel-button": "Cancel"
      },
      "notifications": {
        "launched-title": "New Fleet account was launched!",
        "launched-summary": "Invitation email was sent to \"{email}\"",
        "updated": "Fleet \"{name}\" successfully updated.",
        "suspended": "Fleet \"{name}\" successfully suspended.",
        "resumed": "Fleet \"{name}\" successfully resumed.",
        "deleted": "Fleet \"{name}\" successfully deleted."
      },
      "errors": {
        "demo-limits-reached": "You reached the maximum number of Demo Fleet accounts.",
        "unknown": "Something went wrong. Please try again later or contact support."
      },
      "pricing-plans": {
        "0": "Track & Trace",
        "1": "Standard inSights",
        "2": "Advanced inSights",
        "3": "Premium inSights"
      }
    }
  },
  "unit": {
    "hour": "hour",
    "minute": "min",
    "second": "second",
    "day": "day",
    "week": "week"
  },
  "users": {
    "title": "Users",
    "add-btn": "Add new user",
    "form": {
      "header-update": "Edit user",
      "header-create": "Add new user",
      "name": "Name",
      "name-placeholder": "Enter name",
      "email": "Email",
      "phone": "Phone number",
      "role": "Role",
      "invite-user": "Invite user",
      "select-role": "Select a Role"
    },
    "grid": {
      "user": "User",
      "role": "Role",
      "last-activity": "Last activity",
      "edit-btn": "Edit user",
      "delete-btn": "Delete user",
      "invited-msg": "Invited",
      "resend-invite-msg": "Click to resend invitation",
      "login-as-btn": "Login as",
      "no-data-title": "No users added"
    },
    "delete-dialog": {
      "summary": "Are you sure you want to delete user {name}?",
      "description": "All user data will be irretrievably removed.",
      "confirm-button": "Yes, delete",
      "cancel-button": "Cancel"
    },
    "created-message": {
      "summary": "New User was invited!",
      "detail": "Invitation was sent to {email}"
    },
    "updated-message": {
      "summary": "Success",
      "detail": "Changes were saved!"
    },
    "deleted-message": {
      "summary": "Success",
      "detail": "User \"{name}\" was deleted!"
    },
    "invited-message": {
      "summary": "Invitation resent!",
      "detail": "Invitation was sent again to {email}"
    },
    "profile": {
      "title": "Profile",
      "change-password": "Change password",
      "profile-photo": "Profile photo",
      "recommended-resolution": "Recommended resolution",
      "account-settings": "Account settings",
      "push-notifications": "Push notifications",
      "pushes-denied": "Push notifications denied",
      "pushes-denied-summary": "It looks like push notifications are disabled in your system settings or browser settings. Please enable and try again.",
      "edit-profile": "Edit profile"
    },
    "roles": {
      "1": "Owner",
      "2": "Admin",
      "3": "Support",
      "4": "Owner",
      "5": "Admin",
      "6": "Support",
      "7": "Finance",
      "8": "View Only",
      "9": "Owner",
      "10": "Admin",
      "11": "Finance",
      "12": "View Only",
      "13": "Installer",
      "14": "Dispatch",
      "15": "Maintenance",
      "16": "Demo"
    },
    "demo-role-message": "Your user role does not allow modifying any item."
  },
  "date-time": {
    "month-short": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "month-full": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },
  "export": {
    "xls": "XLS (XLSX)",
    "csv": "CSV",
    "pdf": "PDF",
    "description": "When the filter is enabled, only the filtered records will be exported."
  },
  "filter-panel": {
    "filter-and-sort": "Filter and Sort",
    "reset-all": "Reset all",
    "caption": "Organize content to find what you need.",
    "sort-by": "Sort by",
    "select-all": "Select all",
    "clear": "Clear",
    "default": "Default",
    "action-buttons": {
      "cancel-button": "Cancel",
      "apply-button": "Apply"
    }
  },
  "landscape-overlay": {
    "turn-request": "Please turn your phone to continue.",
    "portrait-orientation-text": "This app is designed to work on portrait orientation only."
  },
  "bulk-actions": {
    "completed": "Completed",
    "take-time": "It may take some time"
  },
  "error-pages": {
    "back-to-app": "Back to App",
    "not-found-page": {
      "caption": "Page not found",
      "description": "Sorry, the page you're looking for can't be found."
    },
    "maintenance-page": {
      "caption": "We'll be right back!",
      "description": "We're making improvements. Please check back soon."
    },
    "unexpected-error-page": {
      "caption": "Unexpected error",
      "description": "The system could not fulfill your request. Please try again or contact support."
    },
    "access-denied-page": {
      "caption": "Access denied",
      "description": "You don't have permission to access this page.",
      "log-out": "Log out"
    }
  },
  "pwa-install-guide": {
    "install-mobile-app": "Install mobile app",
    "quick-access": "Get quick access to the essential features, instant alert notifications, and a smooth, on-the-go experience.",
    "tap-on": "Tap on option",
    "button-in-browser": "button in { message } browser.",
    "select": "Select",
    "add-to-home-screen": "Add to Home Screen.",
    "add-to-home-screen-chrome": "Add to Home Screen.",
    "choose-name": "Choose a clear name for your App to ensure easy discovery.",
    "find-place": "Find the best place for your app and start managing your fleet!"
  }
}
